export default function SetTheme(surveyCreator: any, model: any) {
  const custom: string = "custom";
  const themePlugin = surveyCreator.getPlugin("theme");
  const jsonTheme = JSON.parse(model?.theme);
  if (jsonTheme?.themeName === custom) {
    surveyCreator.theme = jsonTheme;

    const t = Object.create(jsonTheme);
    themePlugin.addTheme(t, true);
    if (surveyCreator.activeTab === "theme") {
      surveyCreator.showDesigner();
      surveyCreator.makeNewViewActive("theme");
    }
  }
}

import * as SurveyCore from "survey-core";
import { PHONE_REGEX } from "../constants/Regex";

export const ValidateEmptyPhone = (params: any) => {
  if (!params[0]) return true;
  return !(params[0].countrycode && !params[0].phone);
};

export const ValidatePhone = (params: any) => {
  if (!params[0] || !params[0].phone) {
    return true;
  }
  return PHONE_REGEX.test(params[0].phone);
};

export function RegisterPhoneValidators(question: any) {
  // Add an Expression validator
  if (question.validators.length === 0) {
    const expressionEmptyPhone = `validateEmptyPhone({${question.name}})`;
    const expressionRegexPhone = `validatePhone({${question.name}})`;

    const expressionValidatorEmptyPhone = new SurveyCore.ExpressionValidator(expressionEmptyPhone);
    expressionValidatorEmptyPhone.text = "Phone field is empty";
    question.validators.push(expressionValidatorEmptyPhone);

    const expressionValidatorRegexPhone = new SurveyCore.ExpressionValidator(expressionRegexPhone);
    expressionValidatorRegexPhone.text = "Must contain only numbers and ()- .";
    question.validators.push(expressionValidatorRegexPhone);
  }
}

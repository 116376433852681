import { useReducer } from "react";
import DialogContext from "../store/DialogContext";
import { initialState, dialogReducer } from "../store/DialogReducer";

function DialogContextProvider({ children }: any) {
  const [state, dispatch] = useReducer(dialogReducer, initialState);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <DialogContext.Provider value={[state, dispatch]}>{children}</DialogContext.Provider>;
}

export default DialogContextProvider;

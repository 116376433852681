import React, { useState } from "react";
import * as SurveyCreator from "survey-creator-react";
import "survey-core/defaultV2.css";
import "../../common/styles/defaultV2_custom.css";
import { useBeforeunload } from "react-beforeunload";
import useDialog from "../../common/hooks/useDialog";
import { useLoading } from "../../common/providers/LoadingSpinner";
import { DEFAULT_JSON, SURVEY_OPTIONS } from "../survey-customization/SurveyOptions";
import Spinner from "../../common/components/Spinner";
import SetLicenseKey from "../../common/functions/SetLicenseKey";
import ISubscriptionsEditorCreatorProps from "../interfaces/ISubscriptionsEditorCreatorProps";
import SaveSurveyFunc from "../functions/SaveSurveyFunc";
import Email from "../component-collection/Email";
import OnQuestionAdded from "../functions/OnQuestionAdded";
import OnElementAllowOperations from "../functions/OnElementAllowOperations";
import Icons from "../survey-customization/Icons";
import Phone from "../component-collection/Phone";
import { RemoveItemFromToolbox } from "../helpers/ToolboxHelper";
import OnModified from "../functions/OnModified";
import ChangeToolboxCategories from "../survey-customization/ChangeToolboxCategories";
import OrderToolbox from "../survey-customization/OrderToolbox";
import Consent from "../../common/component-collection/Consent";
import i18next from "../../common/localization/i18n";
import IconsCommon from "../../common/survey-customization/IconsCommon";
import SetLocale from "../../common/functions/SetLocale";
import subscriptionsEditorStringsEn from "../localization/SubscriptionsEditorEn";
import subscriptionsEditorStringsFr from "../localization/SubscriptionsEditorFr";
import SaveButton from "../../common/buttons/SaveButton";
import CustomizeToolbar from "../../common/survey-customization/CustomizeToolbar";
import ThemeConfiguration from "../../common/survey-customization/ThemeConfiguration";
import OnUploadFile from "../../common/functions/OnUploadFile";
import SetTheme from "../../common/functions/SetTheme";
import Settings from "../survey-customization/Settings";
import SurveyLocalization from "../survey-customization/SurveyLocalization";
import SettingsPanel from "../../common/survey-customization/SettingsPanel";
import OnSurveyInstanceCreated from "../../common/functions/OnSurveyInstanceCreated";
import SurveySerializerCommon from "../../common/survey-customization/SurveySerializerCommon";
import SurveySerializer from "../survey-customization/SurveySerializer";
import PropertyGridEditorCollection from "../../common/survey-customization/PropertyGridEditorCollection";
import CkEditor from "../../common/external-components/CkEditor";
import ItemValue from "../component-collection/ItemValue";
import Subscriptions from "../component-collection/Subscriptions";
import GlobalUnsubscribeEmail from "../component-collection/GlobalUnsubscribeEmail";
import GlobalUnsubscribeSms from "../component-collection/GlobalUnsubscribeSms";
import CommonTopBarDropdown from "../../common/buttons/TopBarDropdown";
import OnShowingProperty from "../functions/OnShowingProperty";
// import { DropdownItemID } from "../../common/enums/DropdownItem";

function SubscriptionCreatorWidget({
  subscriptionsEditorModel,
  subscriptionsStateParams,
}: ISubscriptionsEditorCreatorProps) {
  const { dialog } = useDialog();
  const [isModified, setIsModified] = useState("");
  const [creator, setCreator] = useState<SurveyCreator.SurveyCreator>();
  const { loading, setLoading } = useLoading();

  SetLocale(subscriptionsStateParams, subscriptionsEditorStringsEn, subscriptionsEditorStringsFr);
  SurveySerializer();
  SurveySerializerCommon();
  Icons();
  IconsCommon();
  SettingsPanel();
  SetLicenseKey();
  Email();
  GlobalUnsubscribeEmail();
  GlobalUnsubscribeSms(subscriptionsStateParams);
  ItemValue(subscriptionsStateParams);
  Phone(subscriptionsStateParams);
  Consent(subscriptionsStateParams, dialog, i18next.t("NEW_SUBSCRIPTIONS_EDITOR.CONSENT.CONSENT"));
  Settings();
  PropertyGridEditorCollection();
  CkEditor();

  // @ts-ignore
  useBeforeunload(isModified !== "" ? (event) => event.preventDefault() : null);

  if (creator === undefined) {
    SURVEY_OPTIONS.showJSONEditorTab = !subscriptionsStateParams.isProduction;
    // @ts-ignore
    const surveyCreator = new SurveyCreator.SurveyCreator(SURVEY_OPTIONS);
    Subscriptions(surveyCreator, subscriptionsStateParams);
    ChangeToolboxCategories(surveyCreator);
    ThemeConfiguration(surveyCreator);
    CustomizeToolbar(surveyCreator);
    SurveyLocalization(subscriptionsStateParams);
    SaveButton(surveyCreator);
    CommonTopBarDropdown(surveyCreator, subscriptionsStateParams, setLoading, dialog, "");

    surveyCreator.onSurveyInstanceCreated.add((sender: any, options: any) => {
      OnSurveyInstanceCreated(sender, options);
    });

    surveyCreator.onUploadFile.add((sender: any, options: any) => {
      OnUploadFile(sender, options);
    });

    surveyCreator.onShowingProperty.add((sender: any, options: any) => {
      OnShowingProperty(sender, options, subscriptionsStateParams);
    });

    OrderToolbox(surveyCreator);

    if (subscriptionsEditorModel && subscriptionsEditorModel.json) {
      surveyCreator.JSON = JSON.parse(subscriptionsEditorModel.json);
    } else {
      surveyCreator.JSON = DEFAULT_JSON;
    }

    SetTheme(surveyCreator, subscriptionsEditorModel);

    surveyCreator.saveSurveyFunc = () => {
      SaveSurveyFunc(
        surveyCreator,
        subscriptionsStateParams,
        setLoading,
        subscriptionsEditorModel,
        dialog,
        setIsModified,
      );
    };

    surveyCreator.saveThemeFunc = () => {
      surveyCreator.saveSurvey();
    };

    surveyCreator.onElementAllowOperations.add((_, options: any) => {
      OnElementAllowOperations(_, options);
    });

    surveyCreator.onQuestionAdded.add((sender: any, options: any) =>
      OnQuestionAdded(sender, options, subscriptionsStateParams),
    );

    surveyCreator.onStateChanged.add((sender: any, options: any) => {
      setIsModified(options);
    });
    surveyCreator.onModified.add((_, options: any) => {
      setIsModified(options);
      OnModified(_, options, subscriptionsStateParams);
    });

    surveyCreator.survey.completeText = "Save Preferences";

    surveyCreator.survey
      .getAllQuestions(false)
      .forEach((q) => RemoveItemFromToolbox(surveyCreator, q.getType(), subscriptionsStateParams));

    setCreator(surveyCreator);
  }

  return (
    <div className="form-creator-widget">
      {loading && <Spinner />}
      {creator === undefined ? <div /> : <SurveyCreator.SurveyCreatorComponent creator={creator} />}
    </div>
  );
}

export default SubscriptionCreatorWidget;

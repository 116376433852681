import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import SubscriptionType from "../../form-editor/data/SubscriptionType";
import { SetSubscriptionsChoices } from "./ItemValue";
import SetMapSubscriptionAttributes from "../../common/helpers/SetMapSubscriptionAttributes";

export default function Subscriptions(
  creator: any,
  subscriptionsStateParams: SubscriptionsStateParams,
) {
  const checkboxComponent = creator.toolbox.getItemByName("checkbox");
  checkboxComponent.iconName = "icon-subscriptions";
  checkboxComponent.title = i18next.t("NEW_SUBSCRIPTIONS_EDITOR.SUBSCRIPTIONS.TITLE");

  if (subscriptionsStateParams.isOptIn) {
    SurveyCore.Serializer.findProperty("checkbox", "choices").visibleIndex = 1;
  }

  SurveyCore.Serializer.getProperty("checkbox", "showOtherItem").visible = false;

  SurveyCore.Serializer.addProperty("checkbox", {
    name: "subscriptionsType",
    displayName: i18next.t("NEW_SUBSCRIPTIONS_EDITOR.SUBSCRIPTIONS.CHOOSE_SUBSCRIPTION_TYPE"),
    isRequired: false,
    category: "choices",
    type: "checkbox",
    visibleIndex: 0,
    isSerializable: true,
    isLightSerializable: true,
    categoryIndex: 6,
    visible: subscriptionsStateParams.isOptIn,
    choices: SubscriptionType.filter((s) => s.value != null),
    onSettingValue: (surveyElement: any, value: any) => {
      if (surveyElement.getPropertyValue("subscriptionsType") !== value) {
        surveyElement.choices = null;
      }
      SetMapSubscriptionAttributes(
        SetSubscriptionsChoices,
        subscriptionsStateParams,
        value || "email",
        true,
      );
      return value;
    },
    enableIf: (obj: any) => {
      const checkbox = obj?.survey
        ?.getAllQuestions()
        ?.filter((q: any) => q.getType() === "checkbox");
      if (checkbox?.[0]?.subscriptionsType === "email" && checkbox.length > 1) {
        return false;
      }
      if (checkbox?.[0]?.subscriptionsType === "phone" && checkbox.length > 1) {
        return false;
      }
      return true;
    },
    onSetValue: (surveyElement: any, value: any) => {
      if (surveyElement.survey.state === "empty") {
        SetMapSubscriptionAttributes(
          SetSubscriptionsChoices,
          subscriptionsStateParams,
          value,
          true,
        );
      }
      const checkbox = surveyElement.survey
        .getAllQuestions()
        ?.filter((q: any) => q.getType() === "checkbox");
      surveyElement.setPropertyValue("subscriptionsType", value);

      if (checkbox?.[0]?.subscriptionsType === "email" && checkbox.length > 1) {
        surveyElement.setPropertyValue("subscriptionsType", "phone");
      }
      if (checkbox?.[0]?.subscriptionsType === "phone" && checkbox.length > 1) {
        surveyElement.setPropertyValue("subscriptionsType", "email");
      }
    },
  });
}

import { AddItemIntoToolbox, RemoveItemFromToolbox } from "../helpers/ToolboxHelper";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";

export default function OnModified(
  sender: any,
  options: any,
  subscriptionsStateParams: SubscriptionsStateParams,
) {
  if (options.type === "OBJECT_DELETED") {
    const el = options.target;
    if (el.isPanel || el.isPage) {
      el.questions.forEach((q: any) =>
        AddItemIntoToolbox(sender, q.getType(), subscriptionsStateParams),
      );
    }
    AddItemIntoToolbox(sender, el.getType(), subscriptionsStateParams);
  }
  if (options.type === "QUESTION_CONVERTED") {
    const oldType = options.oldValue.getType();
    const newType = options.className;
    AddItemIntoToolbox(sender, oldType, subscriptionsStateParams);
    RemoveItemFromToolbox(sender, newType, subscriptionsStateParams);
  }
  if (options.type === "THEME_MODIFIED") {
    if (options.value.backgroundImage === undefined && sender.JSON.backgroundImage !== undefined) {
      const value = sender.JSON;
      delete value.backgroundImage;
      sender.JSON = value;
    }
  }
}

import { SurveyQuestionEditorDefinition } from "survey-creator-core";
import * as SurveyCore from "survey-core";
import ItemValueCommon from "../../common/component-collection/ItemValueCommon";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import i18next from "../../common/localization/i18n";
import { SupportedCRM } from "../../common/enums/SupportedCRM";

type Option = {
  value: string;
  text: string;
};
const Subscriptions: { [option: string]: Option[] } = {};
export function SetSubscriptionsChoices(value: string, options: Option[]) {
  if (!Subscriptions[value]) {
    Subscriptions[value] = options;
  }
}

export default function ItemValue(subscriptionsStateParams: SubscriptionsStateParams) {
  if (subscriptionsStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyQuestionEditorDefinition.definition["itemvalue[]@choices"].tabs = [
      { name: "subscriptionListMap", visible: true },
      { name: "value", visible: true },
      { name: "leadChoiceMap", visible: true },
      { name: "contactChoiceMap", visible: true },
      { name: "trueMap", visible: true },
      { name: "falseMap", visible: true },
      { name: "leadFalseMap", visible: true },
      { name: "contactFalseMap", visible: true },
      { name: "contactTrueMap", visible: true },
      { name: "leadTrueMap", visible: true },
    ];
  } else {
    SurveyQuestionEditorDefinition.definition["itemvalue[]@choices"].tabs = [
      { name: "subscriptionListMap", visible: true },
      { name: "value", visible: true },
      { name: "contactChoiceMap", visible: true },
      { name: "trueMap", visible: true },
      { name: "falseMap", visible: true },
      { name: "contactFalseMap", visible: true },
      { name: "contactTrueMap", visible: true },
    ];
  }

  SurveyCore.Serializer.addProperty("itemvalue", {
    name: "subscriptionListMap",
    type: "dropdown",
    displayName: i18next.t("NEW_FORM_EDITOR.ITEM_VALUE.SUBSCRIPTION_LIST"),
    isSerializable: true,
    showMode: "form",
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      // eslint-disable-next-line no-underscore-dangle
      const selectedAttribute = obj?._locOwner?.subscriptionsType;
      if (selectedAttribute) {
        choicesCallback(Subscriptions[selectedAttribute]);
      }
    },
  });

  ItemValueCommon(subscriptionsStateParams);
}

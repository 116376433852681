import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";

export default function OnShowingProperty(
  sender: any,
  options: any,
  subscriptionsStateParams: SubscriptionsStateParams,
) {
  if (options.obj.getType() === "email_field") {
    options.canShow = options.property.name !== "defaultValue";
  }

  if (options.property.name === "subscriptionListMap") {
    options.canShow = false;
  }
  if (options.property.name === "trueMap") {
    options.canShow = false;
  }
  if (options.property.name === "falseMap") {
    options.canShow = false;
  }
  if (options.property.name === "leadChoiceMap") {
    options.canShow = false;
  }
  if (options.property.name === "contactChoiceMap") {
    options.canShow = false;
  }
  if (options.property.name === "contactTrueMap") {
    options.canShow = false;
  }
  if (options.property.name === "contactFalseMap") {
    options.canShow = false;
  }
  if (options.property.name === "leadTrueMap") {
    options.canShow = false;
  }
  if (options.property.name === "leadFalseMap") {
    options.canShow = false;
  }
  if (options.obj.getType() === "checkbox" && options?.obj?.crmFieldType === "subscription_list") {
    if (
      options.property.name === "choicesByUrl" ||
      options.property.name === "choicesFromQuestion" ||
      options.property.name === "maxSelectedChoices" ||
      options.property.name === "minSelectedChoices" ||
      options.property.name === "separateSpecialChoices" ||
      options.property.name === "showOtherItem"
    ) {
      options.canShow = false;
    }
  }

  if (
    (options?.obj?.crmFieldType === "global_unsubscribe_email" ||
      options?.obj?.crmFieldType === "global_unsubscribe_sms") &&
    options.property.name === "isRequired"
  ) {
    options.canShow = false;
  }

  if (options?.parentObj?.crmFieldType === "subscription_list") {
    if (options.property.name === "subscriptionListMap") {
      options.canShow = true;
    }
    if (options.property.name === "trueMap") {
      options.canShow = true;
    }
    if (options.property.name === "falseMap") {
      options.canShow = true;
    }
  }

  if (options?.parentObj?.getType() === "consent") {
    if (options.property.name === "leadChoiceMap") {
      options.canShow = true;
    }
    if (options.property.name === "contactChoiceMap") {
      options.canShow = true;
    }
    if (options.property.name === "contactTrueMap") {
      options.canShow = true;
    }
    if (options.property.name === "contactFalseMap") {
      options.canShow = true;
    }
    if (options.property.name === "leadTrueMap") {
      options.canShow = true;
    }
    if (options.property.name === "leadFalseMap") {
      options.canShow = true;
    }
  }
}

import { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

// @ts-ignore
// eslint-disable-next-line react/prop-types
export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { loading, setLoading };
  // @ts-ignore
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}

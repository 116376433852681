import React from "react";
import { createPortal } from "react-dom";
import useDialog from "../hooks/useDialog";
import { DialogType } from "../enums/DialogType";

const Dialog = () => {
  const { onConfirm, onCancel, dialogState } = useDialog();
  const portalElement = document.getElementById("portal");
  let component = null;
  if (dialogState?.dialogType === DialogType.Confirm) {
    const yesButton = dialogState?.yesButton && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className="btn yes-btn" onClick={onConfirm}>
        {dialogState?.yesButton}
      </div>
    );
    component = dialogState.show ? (
      <div className="portal-overlay">
        <div className="confirm-dialog">
          <div className="header">{(dialogState?.title && dialogState.title) || "Error"}</div>
          <p className="body">{dialogState?.text && dialogState.text}</p>
          <div className="confirm-dialog__footer">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="btn no-btn" onClick={onCancel}>
              Cancel
            </div>
            {yesButton}
          </div>
        </div>
      </div>
    ) : null;
  }

  if (dialogState?.dialogType === DialogType.CheckMapping) {
    component = dialogState.show ? (
      <div className="portal-overlay">
        <div className="confirm-dialog">
          <div className="header">{(dialogState?.title && dialogState.title) || ""}</div>
          <div className="body-mappings-container">
            <p className="body">{dialogState?.text && dialogState.text}</p>
            <ul className="body">
              {dialogState?.bodyModel?.fields.map((f: string) => <li key={f}>{f}</li>)}
            </ul>
          </div>
          <div className="confirm-dialog__footer">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="btn no-btn" onClick={onCancel}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  // @ts-ignore
  return createPortal(component, portalElement);
};
export default Dialog;

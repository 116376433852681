import i18next from "../../common/localization/i18n";
import { DialogType } from "../../common/enums/DialogType";

function OnElementDeleting(surveyCreator: any, dialog: any) {
  let isEmailDeleted = false;
  surveyCreator.onElementDeleting.add(async (sender: any, options: any) => {
    if (options.element.getType() === "text" && options.element.inputType === "email") {
      if (!isEmailDeleted) {
        options.allowing = false;
        isEmailDeleted = true;
        if (
          await dialog(
            DialogType.Confirm,
            i18next.t("NEW_FORM_EDITOR.ON_ELEMENT_DELETING_DIALOG.TITLE"),
            i18next.t("NEW_FORM_EDITOR.ON_ELEMENT_DELETING_DIALOG.TEXT"),
            i18next.t("NEW_FORM_EDITOR.ON_ELEMENT_DELETING_DIALOG.CONTINUE"),
          )
        ) {
          options.allowing = true;
          surveyCreator.deleteElement(options.element);
        } else {
          isEmailDeleted = false;
        }
      } else {
        isEmailDeleted = false;
      }
    }
  });
}

export default OnElementDeleting;

const selectors = ["backgroundImage", "imageLink", "logo"];

function addSizeWarning() {
  selectors.forEach((element) => {
    const selection =
      document.querySelector(`div[data-name='${element}'] .spg-question__content`) ?? null;
    if (selection !== null) {
      selection.classList.add("cd_error_file_size");
    }
  });
}

function removeSizeWarning() {
  selectors.forEach((element) => {
    const selection =
      document.querySelector(`div[data-name='${element}'] .spg-question__content`) ?? null;
    if (selection !== null) {
      selection.classList.remove("cd_error_file_size");
    }
  });
}

function getBase64(file: any) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return reader;
}

function OnUploadFile(sender: any, options: any) {
  removeSizeWarning();
  const file = options.files[0];
  const base64Img = getBase64(file);
  const maxFileSizeMB = 5;
  if (file.size < maxFileSizeMB * 1048576) {
    base64Img.onload = function () {
      options.callback("success", base64Img.result);
    };
  } else {
    addSizeWarning();
  }
}

export default OnUploadFile;

import * as SurveyCreatorCore from "survey-creator-core";

export default function PropertyGridEditorCollection() {
  SurveyCreatorCore.PropertyGridEditorCollection.register({
    fit: (prop) => {
      return prop.name === "text";
    },
    getJSON: (obj: any, prop, options: any) => {
      if (options?.selectedElement?.crmFieldType === "consent") {
        return {
          type: "editor",
        };
      }
      return {
        type: "text",
      };
    },
  });
}

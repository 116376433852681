import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";

function GetSubscriptionsJson(subscriptionsStateParams: SubscriptionsStateParams) {
  const subscriptionsState: SubscriptionsStateParams = subscriptionsStateParams;
  if (subscriptionsState == null || subscriptionsState.webContentId === undefined) {
    return null;
  }

  let url = `${process.env.REACT_APP_API_URL}/api/newsubscriptionseditor/${subscriptionsState.webContentId}?`;
  url += new URLSearchParams({
    accountKey: subscriptionsState.accountKey,
    sessionId: subscriptionsState.sessionId,
    userlcid: subscriptionsState.userlcid,
    orgname: subscriptionsState.orgname,
    userid: subscriptionsState.userid,
  });

  return fetch(url, {
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

export default GetSubscriptionsJson;

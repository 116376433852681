import { DialogType } from "../enums/DialogType";

export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";

export const initialState = {
  dialogType: DialogType,
  title: "",
  text: "",
  yesButton: "",
  bodyModel: Object,
  show: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last,@typescript-eslint/no-unused-vars
export const dialogReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        dialogType: action.payload.dialogType,
        title: action.payload.title,
        text: action.payload.text,
        yesButton: action.payload.yesButton,
        bodyModel: action.payload.bodyModel,
        show: true,
      };
    case HIDE_DIALOG:
      return initialState;
    default:
      return initialState;
  }
};

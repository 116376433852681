import { Serializer } from "survey-core";
import i18next from "../localization/i18n";

export default function SurveySerializerCommon() {
  Serializer.addProperty("question", {
    name: "crmFieldType:text",
    type: "text",
    default: "",
    isSerializable: true,
    visible: false,
  });

  Serializer.addProperty("survey", {
    name: "captcha",
    type: "boolean",
    displayName: i18next.t("NEW_SUBSCRIPTIONS_EDITOR.SHOW_CAPTCHA"),
    category: "toolboxCaptchaCategory",
    default: true,
  });

  Serializer.removeProperty("file", "allowMultiple");
  Serializer.findProperty("file", "storeDataAsText").visible = false;
  Serializer.removeProperty("text", "autocomplete");
  Serializer.findProperty("question", "correctAnswer").visible = false;
  Serializer.findProperty("question", "clearIfInvisible").visible = false;
  Serializer.findProperty("text", "textUpdateMode").visible = false;
  Serializer.findProperty("comment", "textUpdateMode").visible = false;
  Serializer.findProperty("question", "useDisplayValuesInDynamicTexts").visible = false;
  Serializer.findProperty("checkbox", "valuePropertyName").visible = false;
  Serializer.findProperty("question", "valueName").visible = false;
  Serializer.findProperty("survey", "locale").visible = false;
  Serializer.removeProperty("text", "dataList");

  Serializer.findProperty("text", "inputMask").visible = false;
  Serializer.findProperty("text", "inputFormat").visible = false;
  Serializer.findProperty("survey", "cookieName").visible = false;
}

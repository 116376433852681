import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import SubscriptionsModel from "../interfaces/SubscriptionsModel";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import GetSubscriptionList from "../api/GetSubscriptionList";

let subscriptionsEmail: { value: string; text: string }[] | undefined;
let subscriptionsPhone: { value: string; text: string }[] | undefined;

function SetModel(data: [SubscriptionsModel]) {
  return data.map((c: SubscriptionsModel) => {
    return { value: c.id, text: c.name };
  });
}
export default function SetMapSubscriptionAttributes(
  choicesCallback: any,
  stateParams: FormStateParams | SubscriptionsStateParams,
  type: string,
  isSubscriptionEditor: boolean = false,
) {
  if (type === "email" && subscriptionsEmail) {
    if (isSubscriptionEditor) {
      choicesCallback("email", subscriptionsEmail);
    } else {
      choicesCallback(subscriptionsEmail);
    }
    return;
  }
  if (type === "phone" && subscriptionsPhone) {
    if (isSubscriptionEditor) {
      choicesCallback("phone", subscriptionsPhone);
    } else {
      choicesCallback(subscriptionsPhone);
    }
    return;
  }
  GetSubscriptionList(stateParams, type)?.then((data: [SubscriptionsModel]) => {
    if (!data || data.length < 1) {
      choicesCallback([{}]);
    } else {
      if (type === "email") {
        subscriptionsEmail = SetModel(data);
        if (isSubscriptionEditor) {
          choicesCallback("email", subscriptionsEmail);
        } else {
          choicesCallback(subscriptionsEmail);
        }
      }
      if (type === "phone") {
        subscriptionsPhone = SetModel(data);
        if (isSubscriptionEditor) {
          choicesCallback("phone", subscriptionsPhone);
        } else {
          choicesCallback(subscriptionsPhone);
        }
      }
    }
  });
}

import OrderToolbox from "../survey-customization/OrderToolbox";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";

const singleNames = [
  "email_field",
  "phone_field",
  "global_unsubscribe_email",
  "global_unsubscribe_sms",
];
const toolboxItems: any = {};

export function AddItemIntoToolbox(
  creator: any,
  qType: any,
  subscriptionsStateParams: SubscriptionsStateParams,
) {
  if (
    singleNames.indexOf(qType) > -1 ||
    (qType === "checkbox" &&
      creator.survey.getAllQuestions()?.filter((q: any) => q.getType() === "checkbox").length <
        2) ||
    subscriptionsStateParams.isOptIn
  ) {
    const qItem = creator.toolbox.getItemByName(qType);
    if (!qItem && toolboxItems[qType]) {
      creator.toolbox.addItem(toolboxItems[qType]);
      OrderToolbox(creator);
    }
  }
}

export function RemoveItemFromToolbox(
  creator: any,
  qType: any,
  subscriptionsStateParams: SubscriptionsStateParams,
) {
  if (
    singleNames.indexOf(qType) > -1 ||
    (qType === "checkbox" &&
      (creator.survey.getAllQuestions()?.filter((q: any) => q.getType() === "checkbox").length >
        1 ||
        !subscriptionsStateParams.isOptIn))
  ) {
    const qItem = creator.toolbox.getItemByName(qType);
    if (qItem) {
      toolboxItems[qType] = qItem;
      creator.toolbox.removeItem(qType);
    }
  }
}

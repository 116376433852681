import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";

export default function GlobalUnsubscribeEmail() {
  if (!SurveyCore.Serializer.findClass("global_unsubscribe_email")) {
    SurveyCore.ComponentCollection.Instance.add({
      name: "global_unsubscribe_email",
      title: i18next.t("NEW_SUBSCRIPTIONS_EDITOR.UNSUBSCRIBE_ALL_EMAIL.TITLE"),
      iconName: "icon-global-unsubscribe-email",
      questionJSON: {
        type: "checkbox",
        titleLocation: "hidden",
        choices: [
          i18next.t("NEW_SUBSCRIPTIONS_EDITOR.UNSUBSCRIBE_ALL_EMAIL.CHOICES.UNSUBSCRIBE_ALL_EMAIL"),
        ],
      },
    });
  }
}

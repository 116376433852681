import i18next from "../localization/i18n";

const questionMap: Record<string, string> = {
  text: "SINGLE_INPUT",
  checkbox: "CHECKBOX",
  radiogroup: "RADIO_GROUP",
  dropdown: "LIST",
  comment: "COMMENT",
  rating: "RATING",
  imagepicker: "IMAGE_PICKER",
  boolean: "BOOLEAN",
  html: "HTML",
  file: "FILE",
  image: "IMAGE",
  datetimepicker: "DATE_TIME_PICKER",
  country_field: "COUNTRY",
  phone_field: "PHONE",
  email_field: "EMAIL",
  tagbox: "MULTISELECT",
  consent: "CONSENT",
  subscription_list: "SUBSCRIPTION_LIST",
  global_unsubscribe_email: "GLOBAL_UNSUBSCRIBE_EMAIL",
  global_unsubscribe_sms: "GLOBAL_UNSUBSCRIBE_SMS",
};

function GetQuestionName(translationsPrefix: string, key: string) {
  return i18next.t(`${translationsPrefix}.QUESTION_TYPES.${questionMap[key]}`);
}

export default function SetQuestionNameAndTitle(
  sender: any,
  options: any,
  translationsPrefix: string,
  questionType: string,
) {
  const questionName = GetQuestionName(translationsPrefix, questionType);
  const allSelectedQuestions = sender.survey.getAllQuestions().filter((q: any) => {
    return q.getType() === questionType;
  });

  let selectedQuestionsLength = allSelectedQuestions.length;

  let maxNumber = 1;
  const isNumberInNames =
    allSelectedQuestions.filter((q: any) => {
      return (
        options.question.title !== q.title &&
        q.name.match(/\d+$/) !== null &&
        !Number.isNaN(+q.name.match(/\d+$/)[0])
      );
    })?.length > 0;
  try {
    if (isNumberInNames) {
      allSelectedQuestions.forEach((q: any) => {
        const numberString = q.name.match(/\d+$/);
        if (numberString !== null && !Number.isNaN(+numberString[0])) {
          const number = +numberString[0];
          if (number >= maxNumber) {
            maxNumber = +number + 1;
          }
        }
      });
    }
    selectedQuestionsLength = maxNumber;
  } catch {
    /* empty */
  }

  options.question.title = `${questionName} ${selectedQuestionsLength}`.trim();
  options.question.name = `${questionName} ${selectedQuestionsLength}`.trim();
}

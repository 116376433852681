import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";

export default function PublishCustomFields(
  stateParams: FormStateParams | SubscriptionsStateParams,
) {
  let url = `${process.env.REACT_APP_API_URL}/api/surveymetadata/clear/${stateParams.accountKey}?`;
  url += new URLSearchParams({
    accountKey: stateParams.accountKey,
    sessionId: stateParams.sessionId,
    userlcid: stateParams.userlcid,
    orgname: stateParams.orgname,
    userid: stateParams.userid,
  });

  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
  };
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response;
    }
    throw new Error(response.statusText);
  });
}

import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";

function SaveSubscriptionsJson(
  id: string,
  json: JSON,
  webContentName: string | undefined,
  theme: any,
  subscriptionsStateParams: SubscriptionsStateParams,
) {
  if (id === undefined) {
    return null;
  }
  const rp: SubscriptionsStateParams = subscriptionsStateParams;
  if (rp == null || rp.webContentId === undefined) {
    return null;
  }

  let url = `${process.env.REACT_APP_API_URL}/api/newsubscriptionseditor/${id}?`;

  const params = new URLSearchParams({
    accountKey: rp.accountKey,
    sessionId: rp.sessionId,
    userlcid: rp.userlcid,
    orgname: rp.orgname,
    userid: rp.userid,
  });

  url += params.toString();

  const requestOptions: RequestInit = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      json: JSON.stringify(json),
      webContentName,
      theme: theme !== null ? JSON.stringify(theme) : null,
    }),
  };
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

export default SaveSubscriptionsJson;

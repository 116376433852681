import i18next from "i18next";
import * as NEW_SUBSCRIPTIONS_EDITOR_EN from "../../subscription-editor/localization/1033.json";
import * as NEW_SUBSCRIPTIONS_EDITOR_FR from "../../subscription-editor/localization/1036.json";
import * as NEW_FORM_EDITOR_EN from "../../form-editor/localization/1033.json";
import * as NEW_FORM_EDITOR_FR from "../../form-editor/localization/1036.json";
import * as EDITORS_COMMON_EN from "./1033.json";
import * as EDITORS_COMMON_FR from "./1036.json";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  fallbackLng: {
    default: ["en"],
  },
  resources: {
    en: {
      translation: {
        NEW_SUBSCRIPTIONS_EDITOR: NEW_SUBSCRIPTIONS_EDITOR_EN,
        NEW_FORM_EDITOR: NEW_FORM_EDITOR_EN,
        EDITORS_COMMON: EDITORS_COMMON_EN,
      },
    },
    fr: {
      translation: {
        NEW_SUBSCRIPTIONS_EDITOR: NEW_SUBSCRIPTIONS_EDITOR_FR,
        NEW_FORM_EDITOR: NEW_FORM_EDITOR_FR,
        EDITORS_COMMON: EDITORS_COMMON_FR,
      },
    },
  },
});

export default i18next;

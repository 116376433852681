const localeStringsEn = {
  completingSurvey: "Thank you for completing the form",
  survey: {
    externalHelpLink: "Watch and learn how to create forms",
    addLogicItem: "Create a rule to customize the flow of the form.",
    dropQuestion: "Please drop a field here from the Toolbox.",
    deleteQuestion: "Delete Field",
  },
  qt: {
    dropdown: "List",
    text: "Single Input",
    tagbox: "Multiselect",
    comment: "Comment",
    radiogroup: "Radiogroup",
    checkbox: "Checkbox",
    boolean: "Boolean",
    datetimepicker: "Date Time Picker",
    phone_field: "Phone",
    country_field: "Country",
    consent: "Consent",
    subscription_list: "Subscription List",
  },
  ed: {
    survey: "Form",
    settings: "Form Settings",
    settingsTooltip: "Open form settings",
    surveySettingsTooltip: "Open form settings",
    testSurvey: "Preview",
    testSurveyAgain: "Preview Form Again",
    testSurveyWidth: "Form width: ",
    embedSurvey: "Embed Form",
    saveSurvey: "Save Form",
    saveSurveyTooltip: "Save Form",
    surveyResults: "Form Results ",
    surveyTypeName: "Form",
    questionTypeName: "Field",
    newQuestionName: "field",
    addNewQuestion: "New Form Field",
    surveyPlaceHolder:
      "The form is empty. Drag an element from the toolbox or click the button below.",
    toolboxChoiceCategory: "Choice Fields",
    toolboxTextCategory: "Text Input Fields",
    toolboxMatrixCategory: "Matrix Fields",
    resultsTitle: "Field Title",
    resultsName: "Field Name",
    logic: "Form logic",
    designer: "Form Designer",
    fpAddQuestion: "Add field...",
    lg: {
      // eslint-disable-next-line
      completedHtmlOnConditionName: 'Set "Form Complete" page markup',
      question_resetValueName: "Reset field value",
      question_setValueName: "Set field value",
      empty_tab: "Create a rule to customize the flow of the form.",
      trigger_completeName: "Complete form",
      trigger_completeDescription:
        "When the logic expression returns true then the form becomes completed and an end-user see the 'Thank you page'.",
      trigger_skipDescription:
        "When the logic expression returns true then the form skip to / focus the selected field.",
      trigger_completeText: "Form becomes completed",
      trigger_skipText: "Form skip to the field {0}", // {0} question name
      question_visibilityName: "Show (hide) field",
      question_enableName: "Enable (disable) field",
      question_requireName: "Make field required",
      trigger_setvalueName: "Set field",
      trigger_copyvalueName: "Copy field value",
      trigger_skipName: "Skip to field",
      question_visibilityDescription:
        "Make the field visible when the logic expression returns true. Otherwise keep it invisible.",
      question_enableDescription:
        "Make the field enable when the logic expression returns true. Otherwise keep it disabled.",
      field:
        "Make the question enable when the logic expression returns true. Otherwise keep it disabled.",
      question_requireDescription: "Field becomes required when the logic expression returns true.",
      trigger_setvalueDescription:
        "When question values, that are used in the logic expression, are changed and the logic expression returns true, then the value is set to the selected field.",
      trigger_copyvalueDescription:
        "When question values, that are used in the logic expression, are changed and the logic expression returns true, then the value of one selected field is copied to another selected field.",
      trigger_runExpressionDescription:
        "When the logic expression returns true, then the custom expression is performed. You may optionally set this expression result into the selected field",
      question_visibilityText: "Make field {0} visible", // {0} question name
      question_enableText: "Make field {0} enable", // {0} question name
      question_requireText: "Make field {0} required", // {0} question name
      column_visibilityText: "Make column {0} of field {1} visible", // {0} column name, {1} question name
      column_enableText: "Make column {0} of field {1} enable", // {0} column name, {1} question name
      column_requireText: "Make column {0} of field {1} required", // {0} column name, {1} question name
      trigger_setvalueText: "Set into field: {0} value {1}", // {0} question name, {1} setValue
      trigger_copyvalueText: "Copy into field: {0} value from question {1}", // {0} and {1} question names
      trigger_runExpressionText2: " and set its result into field: {0}", // {0} question name
      showAllQuestions: "All Fields",
    },
  },
  pe: {
    theme: {
      themeName: "Theme",
      isPanelless: "Is panelless",
      editorPanel: "Background and corner radius",
      questionPanel: "Background and corner radius",
      primaryColor: "Accent color",
      panelBackgroundTransparency: "Panel and question box opacity",
      questionBackgroundTransparency: "Input element opacity",
      fontSize: "Form font size",
      scale: "Form scale factor",
      cornerRadius: "Corner radius",
      advancedMode: "Advanced mode",
      pageTitle: "Title font",
      pageDescription: "Description font",
      questionTitle: "Title font",
      questionDescription: "Description font",
      editorFont: "Font",
      backgroundOpacity: "Opacity", // Auto-generated string

      "--sjs-font-family": "Form font family",
      "--sjs-general-backcolor-dim": "Background color",
      "--sjs-primary-backcolor": "Accent background colors",
      "--sjs-primary-forecolor": "Accent foreground colors",
      "--sjs-special-red": "Error message colors",
      "--sjs-shadow-small": "Shadow effects",
      "--sjs-shadow-inner": "Shadow effects",
      "--sjs-border-default": "Colors",
    },
    "header@header": {
      surveyDescription: "Form description font",
      headerDescription: "Form description font",
      headerTitle: "Form title font",
      surveyTitle: "Form title font",
    },
    survey: {
      title: "Form title",
      description: "Form description",
    },
    requiredErrorText: "Error message for required fields",
    questionsOrder: "Field order",
    logo: "Form logo",
    mode: "Form display mode",
    defaultValue: "Default Value",
    inputType: "Data type",
    surveyEditorTitle: "Edit form settings",
    includeIntoResult: "Include into form results",
    sendResultOnPageNext: "Save partial form results in progress",
    surveyTitlePlaceholder: "Form Title",
    showPreviewBeforeComplete: "Preview answers before submitting the form",
    conditionSelectQuestion: "Select field...",
    assistantTitle: "Available fields",
    startWithNewLine: "Display the field on a new line",
    textUpdateMode: "Update text field value",
    hideNumber: "Hide field number",
    emptyLogicPopupMessage: "Select a field to start configuring conditions.",
    completeText: "Complete Form button text",
    showCompletedPage: "Show the Form Complete page",
    startSurveyText: "Start Form button text",
    questionTitleLocation: "Field title location",
    maxTimeToFinish: "Time limit to finish the form (in seconds)",
    maxTimeToFinishPage: "Maximum time to finish a page in the form",
    hideIfRowsEmpty: "Hide the field if there are no rows",
    questionsOnPageMode: "Form structure",
    choicesFromQuestion: "Copy choices from the following field",
    hideIfChoicesEmpty: "Hide the field if it contains no choices",
    templateTitleLocation: "Field title location",
    showQuestionNumbers: "Show field numbering",
    completedBeforeHtml: "Markup to show if the user already filled out this form",
    completedHtml: "Form Complete page markup",
    completedHtmlOnCondition: "Dynamic Form Complete page markup",
    loadingHtml: "Markup to show while form model is loading",
    focusFirstQuestionAutomatic: "Focus first field on a new page",
    questionTitleTemplate: "Field title template, default is: '{no}. {require} {title}'",
    setToName: "Target field",
    fromName: "Field to copy answer from",
    gotoName: "Field to skip to",
    tabs: {
      showOnCompleted: "Form Complete",
      logo: "Logo in Form Title",
      question: "Field",
      toolboxMapperCategory: "Mapper",
      toolboxCaptchaCategory: "Captcha",
      questionSettings: "Field Settings",
    },
    noquestions: "There are no fields in the form.",
    triggerCompleteText: "Complete the form if succeed.",
    triggerIsVariable: "Do not put the variable into the form result.",
    riggerGotoName: "Go to the field",
    surveyDescriptionPlaceholder: "Enter a form description",
    cookieName: "Cookie name (to disable run form two times locally)",
    firstPageIsStarted: "The first page in the form is a started page.",
    expressionHelp:
      "You can use curly brackets to get access to the field values: {field1} + {field2}, ({price}*{quantity}) * (100 - {discount}). You can use functions as: iif(), today(), age(), min(), max(), count(), avg() and others.",
    goNextPageAutomatic: "On answering all field, go to the next page automatically",
    requiredText: "The field required symbol(s)",
    questionStartIndex: "Field start index (1, 2 or 'A', 'a')",
    questionErrorLocation: "Field error location",
    triggerGotoName: "Go to the field:",
    clearInvisibleValues: "Clear hidden fields values",
  },
  pv: {
    money: "Money",
    integer: "Integer",
    email: "Email",
    text: "Text",
    url: "Url",
    decimal: "Decimal",
    showAllQuestions: "Show all fields",
    showAnsweredQuestions: "Show answered fields only",
    questions: "Answered fields",
    onSurvey: "Continue throughout the form",
    onComplete: "When the form is completed",
    survey: "Form",
    requiredQuestions: "Answered required fields",
    questionPerPage: "Each field on an individual page",
    singlePage: "All fields on a single page",
    off: "Hide field numbers",
    onHidden: "When the field becomes hidden",
    onHiddenContainer: "When the field or its panel/page becomes hidden",
    correctQuestions: "correct fields",
  },
  theme: {
    questionTitle: "Field box",
    coverInheritWidthFromSurvey: "Same as form",
    surveyDescription: "Form description font",
    surveyTitle: "Main title",
    themeMode: "Field appearance",
    questionBackgroundTransparency: "Field background opacity",
    names: {
      cd_default: "Default",
      custom: "Custom Theme",
      "custom-dark": "Custom Theme",
    },
  },
  ew: {
    showOnPage: "Show form on a page",
    showInWindow: "Show form in a window",
    loadFromServer: "Load Form JSON from server",
  },
  p: {
    widthMode: "Form width mode",
    startSurveyText: "startFormText",
    questionsOrder: "Fields order",
    questionTitleLocation: "Field title location",
    showQuestionNumbers: "Show field numbers",
    focusFirstQuestionAutomatic: "Focus first field automatic",
    questionErrorLocation: "Field error location",
    questionStartIndex: "Field Start Index",
    questionTitleTemplate: "Field title template",
    questionDescriptionLocation: "Field description location",
    questionTitlePattern: "Field title pattern",
    questionsOnPageMode: "Fields on page mode",
    choicesFromQuestion: "Choices from field",
    choicesFromQuestionMode: "Choices from field mode",
    completeText: "completeText",
    completedHtml: "completedHtml",
    showCompletedPage: "showCompletedPage",
  },
  triggers: {
    skiptrigger: "Skip to field",
    completetrigger: "Complete form",
  },
  pehelp: {
    cookieName: "Cookies prevent users from filling out the same form twice.",
  },
};

export default localeStringsEn;

import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";

export default function GetSubscriptionList(
  stateParams: FormStateParams | SubscriptionsStateParams,
  type: string,
) {
  const sp: FormStateParams | SubscriptionsStateParams = stateParams;
  if (sp == null || sp.webContentId === undefined) {
    return null;
  }

  let url = `${process.env.REACT_APP_API_URL}/api/editorscommon/subscriptions?`;
  url += new URLSearchParams({
    accountKey: sp.accountKey,
    sessionId: sp.sessionId,
    userlcid: sp.userlcid,
    orgname: sp.orgname,
    userid: sp.userid,
    type,
  });

  return fetch(url, {
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

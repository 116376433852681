import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import GetLeadContactAttributeOptions from "../../common/api/GetLeadContactAttributeOptions";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import SetMapperAttributes from "../../common/helpers/SetMapperAttributes";
import { EntityType } from "../../common/enums/EntityType";

function BooleanToolbox(formStateParams: FormStateParams) {
  function setChoices(choicesCallback: any, objVal: string, entityType: EntityType) {
    if (!choicesCallback) return;
    GetLeadContactAttributeOptions(formStateParams, entityType, objVal)?.then((data: any) => {
      const cc: any = [];
      (data as any).options.forEach((c: { value: string; label: string }) => {
        cc.push({ value: c.value, text: c.label });
      });
      choicesCallback(cc);
    });
  }

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("boolean", {
      name: "falseLeadMap:dropdown",
      // eslint-disable-next-line
      displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.FALSE_LEAD_VALUE"),
      category: "choices",
      dependsOn: ["leadMap"],
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      choices: (obj: any, choicesCallback: any) => {
        setChoices(choicesCallback, obj.leadMap, EntityType.Lead);
      },
    });
  }

  SurveyCore.Serializer.addProperty("boolean", {
    name: "falseContactMap:dropdown",
    // eslint-disable-next-line
    displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.FALSE_CONTACT_VALUE"),
    category: "choices",
    dependsOn: ["contactMap"],
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    choices: (obj: any, choicesCallback: any) => {
      setChoices(choicesCallback, obj.contactMap, EntityType.Contact);
    },
  });

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("boolean", {
      name: "trueLeadMap:dropdown",
      // eslint-disable-next-line
      displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.TRUE_LEAD_VALUE"),
      category: "choices",
      dependsOn: ["leadMap"],
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      choices: (obj: any, choicesCallback: any) => {
        setChoices(choicesCallback, obj.leadMap, EntityType.Lead);
      },
    });
  }

  SurveyCore.Serializer.addProperty("boolean", {
    name: "trueContactMap:dropdown",
    // eslint-disable-next-line
    displayName: i18next.t("NEW_FORM_EDITOR.BOOLEAN.TRUE_CONTACT_VALUE"),
    category: "choices",
    dependsOn: ["contactMap"],
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    choices: (obj: any, choicesCallback: any) => {
      setChoices(choicesCallback, obj.contactMap, EntityType.Contact);
    },
  });

  SurveyCore.Serializer.findProperty("boolean", "valueTrue").visible = false;
  SurveyCore.Serializer.findProperty("boolean", "valueFalse").visible = false;

  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("boolean", {
      name: "leadMap",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      categoryIndex: 8,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (surveyElement: any, value: any) => {
        surveyElement.setPropertyValue("leadMap", value);
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        SetMapperAttributes("boolean", choicesCallback, formStateParams, EntityType.Lead, obj);
      },
      visibleIndex: 1,
    });
  }

  SurveyCore.Serializer.addProperty("boolean", {
    name: "contactMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "dropdown",
    isSerializable: true,
    isLightSerializable: true,
    categoryIndex: 8,
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    onSetValue: (surveyElement: any, value: any) => {
      surveyElement.setPropertyValue("contactMap", value);
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      SetMapperAttributes("boolean", choicesCallback, formStateParams, EntityType.Contact, obj);
    },
    visibleIndex: 1,
  });

  SurveyCore.Serializer.addProperty("boolean", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
    categoryIndex: 8,
  });
}

export default BooleanToolbox;

import { Converter } from "showdown";

const converter = new Converter();
export default function DoMarkdown(survey: any, options: any) {
  if (options.element.name === "consentItem") {
    const asterisk = " <span style='color:#e74c3c'>*</span>";
    // convert the markdown text to html
    let str = converter.makeHtml(`${options.text}`);
    if (str.indexOf("<p>") === 0) {
      // remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
    }
    // set html
    options.html = str + asterisk;
  }
}

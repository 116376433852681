import GetLeadAttributes from "../api/GetLeadAttributes";
import ContactLeadAttributeModel from "../interfaces/ContactLeadAttributeModel";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import { EntityType } from "../enums/EntityType";
import GetContactAttributes from "../api/GetContactAttributes";

let emailModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let textModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let listModel: { [entityType in EntityType]?: { value: string; text: string }[] } = {};
let multiList: { [entityType in EntityType]?: { value: string; text: string }[] } = {};
let urlModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let dateModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let textAreaModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let dateTimeModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let integerModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let decimalModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let moneyModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let phoneModel: {
  [entityType in EntityType]?: { value: { value: string; length?: number }; text: string }[];
} = {};
let booleanModel: { [entityType in EntityType]?: { value: string; text: string }[] } = {};
let o: any;

function SetModel(type: string, data: [ContactLeadAttributeModel]) {
  const model = data.map((c: ContactLeadAttributeModel) => {
    return { value: { value: c.schemaName, length: c.length }, text: c.displayName };
  });

  return model;
}

function SetListModel(type: string, data: [ContactLeadAttributeModel]) {
  const model = data.map((c: ContactLeadAttributeModel) => {
    return { value: c.schemaName, text: c.displayName };
  });

  return model;
}

function SetModels(
  data: any,
  fieldType: string,
  choicesCallback: any,
  routeParams: FormStateParams | SubscriptionsStateParams,
  entityType: EntityType,
) {
  if (!data) {
    choicesCallback([{}]);
    choicesCallback([{}]);
  } else if (data.length < 1) {
    choicesCallback([{}]);
    choicesCallback([{}]);
  } else {
    if (fieldType === "email") {
      emailModel[entityType] = SetModel(fieldType, data);
      choicesCallback(emailModel[entityType]);
    }
    if (fieldType === "text") {
      textModel[entityType] = SetModel(fieldType, data);
      choicesCallback(textModel[entityType]);
    }
    if (fieldType === "list") {
      listModel[entityType] = SetListModel(fieldType, data);
      choicesCallback(listModel[entityType]);
    }
    if (fieldType === "multiList") {
      multiList[entityType] = SetListModel(fieldType, data);
      choicesCallback(multiList[entityType]);
    }
    if (fieldType === "url") {
      urlModel[entityType] = SetModel(fieldType, data);
      choicesCallback(urlModel[entityType]);
    }
    if (fieldType === "date") {
      dateModel[entityType] = SetModel(fieldType, data);
      choicesCallback(dateModel[entityType]);
    }
    if (fieldType === "textArea") {
      textAreaModel[entityType] = SetModel(fieldType, data);
      choicesCallback(textAreaModel[entityType]);
    }
    if (fieldType === "dateTime") {
      dateTimeModel[entityType] = SetModel(fieldType, data);
      choicesCallback(dateTimeModel[entityType]);
    }
    if (fieldType === "money") {
      moneyModel[entityType] = SetModel(fieldType, data);
      choicesCallback(moneyModel[entityType]);
    }
    if (fieldType === "integer") {
      integerModel[entityType] = SetModel(fieldType, data);
      choicesCallback(integerModel[entityType]);
    }
    if (fieldType === "decimal") {
      decimalModel[entityType] = SetModel(fieldType, data);
      choicesCallback(decimalModel[entityType]);
    }
    if (fieldType === "phone") {
      phoneModel[entityType] = SetModel(fieldType, data);
      choicesCallback(phoneModel[entityType]);
    }
    if (fieldType === "boolean") {
      booleanModel[entityType] = SetListModel(fieldType, data);
      choicesCallback(booleanModel[entityType], entityType);
    }
  }
}

function SetMapperAttributes(
  fieldType: string,
  choicesCallback: any,
  routeParams: FormStateParams | SubscriptionsStateParams,
  entityType: EntityType = EntityType.Lead,
  obj: any = null,
) {
  o = null;
  if (obj) {
    o = obj;
  }

  if (fieldType === "email" && emailModel[entityType]) {
    choicesCallback(emailModel[entityType]);
    return;
  }
  if (fieldType === "text" && textModel[entityType]) {
    choicesCallback(textModel[entityType]);
    return;
  }
  if (fieldType === "list" && listModel[entityType]) {
    choicesCallback(listModel[entityType]);
    return;
  }
  if (fieldType === "date" && dateModel[entityType]) {
    choicesCallback(dateModel[entityType]);
    return;
  }
  if (fieldType === "url" && urlModel[entityType]) {
    choicesCallback(urlModel[entityType]);
    return;
  }
  if (fieldType === "money" && moneyModel[entityType]) {
    choicesCallback(moneyModel[entityType]);
    return;
  }
  if (fieldType === "integer" && integerModel[entityType]) {
    choicesCallback(integerModel[entityType]);
    return;
  }
  if (fieldType === "decimal" && decimalModel[entityType]) {
    choicesCallback(decimalModel[entityType]);
    return;
  }
  if (fieldType === "textArea" && textAreaModel[entityType]) {
    choicesCallback(textAreaModel[entityType]);
    return;
  }
  if (fieldType === "dateTime" && dateTimeModel[entityType]) {
    choicesCallback(dateTimeModel[entityType]);
    return;
  }
  if (fieldType === "multiList" && multiList[entityType]) {
    choicesCallback(multiList[entityType]);
    return;
  }
  if (fieldType === "phone" && phoneModel[entityType]) {
    choicesCallback(phoneModel[entityType]);
    return;
  }
  if (fieldType === "boolean" && booleanModel[entityType]) {
    choicesCallback(booleanModel[entityType]);
    return;
  }
  if (entityType === EntityType.Lead) {
    GetLeadAttributes(routeParams, fieldType)
      ?.then((data: [ContactLeadAttributeModel]) => {
        SetModels(data, fieldType, choicesCallback, routeParams, entityType);
      })
      .catch(() => {
        o.clearErrors();
        o.addError("Server error while loading mappings.");
      });
  }
  if (entityType === EntityType.Contact) {
    GetContactAttributes(routeParams, fieldType)
      ?.then((data: [ContactLeadAttributeModel]) => {
        SetModels(data, fieldType, choicesCallback, routeParams, entityType);
      })
      .catch(() => {
        o.clearErrors();
        o.addError("Server error while loading mappings.");
      });
  }
}

export function ClearMapperAttributes() {
  emailModel = {};
  textModel = {};
  listModel = {};
  multiList = {};
  urlModel = {};
  dateModel = {};
  textAreaModel = {};
  dateTimeModel = {};
  integerModel = {};
  decimalModel = {};
  moneyModel = {};
  phoneModel = {};
  booleanModel = {};
}

export default SetMapperAttributes;

function SetLeadMaxLengthAndValue(obj: any, value: any) {
  if (!value) {
    obj.setPropertyValue("leadMapTemp", {
      value,
      length: null,
    });
    obj.setPropertyValue("leadMap", null);
    obj.setPropertyValue("crmLeadMaxLength", null);
    obj.setPropertyValue("crmMaxLength", null);
  }
  if (value?.length && value?.value) {
    obj.setPropertyValue("crmLeadMaxLength", value.length);
    if (
      !obj.getPropertyValue("crmContactMaxLength") ||
      obj.getPropertyValue("crmContactMaxLength") === 1 ||
      obj.getPropertyValue("crmContactMaxLength") > value.length ||
      obj.getPropertyValue("crmLeadMaxLength") < value.length
    ) {
      obj.setPropertyValue("crmMaxLength", value.length);
    } else {
      obj.setPropertyValue("crmMaxLength", obj.getPropertyValue("crmContactMaxLength"));
    }
  }
  if (value?.value) {
    obj.setPropertyValue("leadMapTemp", { value: value.value, length: value.length });
    obj.setPropertyValue("leadMap", value.value);
  } else {
    obj.setPropertyValue("leadMapTemp", {
      value,
      length: obj.getPropertyValue("crmLeadMaxLength"),
    });
    obj.setPropertyValue("leadMap", value);
  }
}

export default SetLeadMaxLengthAndValue;

import * as SurveyCreatorCore from "survey-creator-core";

function SettingsPanel() {
  // @ts-ignore
  const move = (from: any, to: any, arr: any) => {
    const item = arr.splice(from, 1)[0];
    arr.splice(to, 0, item);
  };
  const deleteItem = (index: any, arr: any) => {
    arr.splice(index, 1);
  };

  let surveyProps = SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.survey.properties;
  const startSurveyIndex = surveyProps?.findIndex((el: any) => el.name === "startSurveyText") || -1;
  if (startSurveyIndex > -1) {
    deleteItem(startSurveyIndex, surveyProps);
  }

  surveyProps = SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.survey.properties;
  const showPreviewBeforeCompleteIndex = surveyProps?.findIndex(
    (el: any) => el.name === "showPreviewBeforeComplete",
  );
  const moveToIndex = surveyProps?.findIndex((el: any) => el.name === "pageNextText") || -1;
  if (moveToIndex > -1) {
    move(showPreviewBeforeCompleteIndex, moveToIndex, surveyProps);
  }

  surveyProps = SurveyCreatorCore.SurveyQuestionEditorDefinition.definition.survey.properties;
  const completeTextIndex = surveyProps?.findIndex((el: any) => el.name === "completeText") || -1;
  if (completeTextIndex > -1) {
    move(completeTextIndex, 0, surveyProps);
  }
}

export default SettingsPanel;

function ChangeToolboxCategories(surveyCreator: any) {
  surveyCreator.toolbox.changeCategories([
    { name: "text", category: "First section" },
    { name: "datetimepicker", category: "First section" },
    { name: "country_field", category: "First section" },
    { name: "phone_field", category: "First section" },
    { name: "consent", category: "First section" },
    { name: "subscription_list", category: "First section" },
    { name: "comment", category: "First section" },
    { name: "radiogroup", category: "First section" },
    { name: "dropdown", category: "First section" },
    { name: "boolean", category: "First section" },
    { name: "checkbox", category: "First section" },
    { name: "image", category: "First section" },
    { name: "tagbox", category: "First section" },
    { name: "file", category: "First section" },
    { name: "html", category: "First section" },
  ]);
}

export default ChangeToolboxCategories;

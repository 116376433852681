import { Serializer } from "survey-core";
import i18next from "../../common/localization/i18n";

function SurveySerializer() {
  Serializer.addProperty("question", {
    name: "formFieldId:text",
    type: "text",
    default: "",
    isSerializable: true,
    visible: false,
  });

  Serializer.addProperty("question", {
    name: "crmId:text",
    type: "text",
    default: "",
    isSerializable: true,
    visible: false,
  });

  Serializer.findProperty("text", "inputType").setChoices([
    "email",
    "text",
    "url",
    "integer",
    "decimal",
    "money",
  ]);
  Serializer.addProperty("survey", {
    name: "limitToOneSubmission:boolean",
    displayName: i18next.t("NEW_SUBSCRIPTIONS_EDITOR.LIMIT_TO_ONE_SUBMISSION"),
    default: false,
    category: "general",
  });
}

export default SurveySerializer;

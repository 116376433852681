import React, { useEffect, useState } from "react";
import FormCreatorWidget from "./FormCreatorWidget";
import FormModel from "../interfaces/FormModel";
import FormStateParams from "../interfaces/FormStateParams";
import useThrowAsyncError from "../../common/hooks/useThrowAsyncError";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import GetFormJson from "../api/GetFormJson";
import Spinner from "../../common/components/Spinner";
import { useLoading } from "../../common/providers/LoadingSpinner";

function FormCreator() {
  const [json, setJson] = useState<FormModel | null>(null);
  const { loading, setLoading } = useLoading();
  const throwAsyncError = useThrowAsyncError();
  const [formStateParams, setFormStateParams] = useState<FormStateParams>({
    accountKey: "",
    orgname: "",
    sessionId: "",
    userid: "",
    userlcid: "",
    webContentId: "",
    supportedCRM: SupportedCRM.MSCRM,
    isProduction: false,
    isOptIn: false,
  });

  const searchParams = new URLSearchParams(window.location.search);
  const rp: FormStateParams = {
    orgname: searchParams.get("orgname"),
    userid: searchParams.get("userid"),
    accountKey: searchParams.get("accountKey"),
    sessionId: searchParams.get("sessionId"),
    userlcid: searchParams.get("userlcid"),
    webContentId: searchParams.get("id"),
    isProduction:
      process.env.REACT_APP_IS_PRODUCTION !== undefined
        ? JSON.parse(process.env.REACT_APP_IS_PRODUCTION.toString().toLowerCase())
        : false,
  } as FormStateParams;
  useEffect(() => {
    if (rp.webContentId !== null) {
      // @ts-ignore
      setLoading(true);
      setFormStateParams(Object.assign(formStateParams, rp));
      GetFormJson(formStateParams)!
        .then((data: any) => {
          formStateParams.supportedCRM = data.supportedCRM;
          formStateParams.isOptIn = data.isOptIn;
          setJson(data);
          // @ts-ignore
          setLoading(false);
        })
        .catch(async (e) => {
          throwAsyncError(e);
          // @ts-ignore
          setLoading(false);
        });
    } else {
      throwAsyncError("");
    }
  }, []);

  if (json === null) {
    return <span className="survey-loader" />;
  }

  return (
    <>
      {loading && <Spinner />}
      <FormCreatorWidget formModel={json} formStateParams={formStateParams} />
    </>
  );
}

export default FormCreator;

import { SurveyCreator } from "survey-creator-react";

function OrderToolbox(surveyCreator: SurveyCreator) {
  surveyCreator.toolbox.orderedQuestions = [
    "text",
    "email_field",
    "datetimepicker",
    "country_field",
    "consent",
    "phone_field",
    "subscription_list",
    "comment",
    "radiogroup",
    "dropdown",
    "checkbox",
    "boolean",
    "tagbox",
    "image",
    "file",
    "html",
  ];
}

export default OrderToolbox;

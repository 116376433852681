import DoMarkdown from "./DoMarkdown";

function OnSurveyInstanceCreated(sender: any, options: any) {
  const logicTab = options.survey.getPanelByName("logic");
  const timer = options.survey.getPanelByName("timer");
  if (logicTab) {
    logicTab.delete();
  }
  if (timer) {
    timer.delete();
  }

  if (
    options.reason === "theme" ||
    options.reason === "test" ||
    options.reason === "designer" ||
    options.reason === "condition-builder"
  ) {
    options.survey.onTextMarkdown.add(DoMarkdown);
  }
}

export default OnSurveyInstanceCreated;

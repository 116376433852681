function CustomizeToolbar(surveyCreator: any) {
  const swapElement = (array: any, indexA: any, indexB: any) => {
    const tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
    return array;
  };
  const saveButtonIndex = surveyCreator.toolbarItems.findIndex((i: any) => i.id === "svd-save");
  if (saveButtonIndex > -1) {
    const arr = swapElement(
      surveyCreator.toolbarItems,
      saveButtonIndex,
      surveyCreator.toolbarItems.length - 1,
    );
    surveyCreator.toolbar.setItems(arr);
  }
}
export default CustomizeToolbar;

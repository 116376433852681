import React from "react";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import PublishCustomFields from "../api/PublishCustomFields";
import { ClearMapperAttributes } from "../helpers/SetMapperAttributes";
import { DialogType } from "../enums/DialogType";

export default function PublishCustomFieldsButton(
  surveyCreator: any,
  routeParams: FormStateParams | SubscriptionsStateParams,
  setLoading: any,
  dialog: any,
) {
  setLoading(true);
  PublishCustomFields(routeParams)
    ?.then(() => {
      ClearMapperAttributes();

      const selected = surveyCreator.selectedElement;
      if (selected.isQuestion) {
        surveyCreator.selectElement(null);
        surveyCreator.selectElement(selected);
        selected.focus();
      }

      // @ts-ignore
      setLoading(false);
    })
    .catch(async (e: any) => {
      // @ts-ignore
      setLoading(false);
      if (e.message === "Unauthorized") {
        if (await dialog(DialogType.Confirm, "Error", "Ooops! Unauthorized error")) {
          return <>getFormJson error</>;
        }
      } else if (await dialog(DialogType.Confirm, "Error", "Publishing server error")) {
        return <>getFormJson error</>;
      }
      return <>getFormJson error</>;
    });
}

export default function OnSurveyPropertyValueChanged(sender: any, options: any) {
  if (options.propertyName === "inputType") {
    if (options.value === "money") {
      options.obj.setPropertyValue("inputMask", "currency");
      options.obj.setPropertyValue("crmFieldType", "money");
    }
    if (options.value === "decimal") {
      options.obj.setPropertyValue("inputMask", "decimal");
      options.obj.setPropertyValue("crmFieldType", "decimal");
    }
    if (options.value === "email") {
      options.obj.setPropertyValue("inputMask", "email");
      options.obj.setPropertyValue("crmFieldType", "email");
    }
    if (options.value === "integer") {
      options.obj.setPropertyValue("inputMask", "integer");
      options.obj.setPropertyValue("crmFieldType", "integer");
    }
    if (options.value === "text") {
      options.obj.setPropertyValue("crmFieldType", "text");
      options.obj.setPropertyValue("inputMask", "none");
    }
    if (options.value === "url") {
      options.obj.setPropertyValue("inputMask", "url");
      options.obj.setPropertyValue("crmFieldType", "url");
    }
  }
}

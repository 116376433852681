function SetContactMaxLengthAndValue(obj: any, value: any) {
  if (!value) {
    obj.setPropertyValue("contactMapTemp", {
      value,
      length: null,
    });
    obj.setPropertyValue("contactMap", null);
    obj.setPropertyValue("crmContactMaxLength", null);
    obj.setPropertyValue("crmMaxLength", null);
  }
  if (value?.length && value?.value) {
    obj.setPropertyValue("crmContactMaxLength", value.length);
    if (
      !obj.getPropertyValue("crmLeadMaxLength") ||
      obj.getPropertyValue("crmLeadMaxLength") === 1 ||
      obj.getPropertyValue("crmLeadMaxLength") > value.length ||
      obj.getPropertyValue("crmContactMaxLength") < value.length
    ) {
      obj.setPropertyValue("crmMaxLength", value.length);
    } else {
      obj.setPropertyValue("crmMaxLength", obj.getPropertyValue("crmLeadMaxLength"));
    }
  }
  if (value?.value) {
    obj.setPropertyValue("contactMapTemp", { value: value.value, length: value.length });
    obj.setPropertyValue("contactMap", value.value);
  } else {
    obj.setPropertyValue("contactMapTemp", {
      value,
      length: obj.getPropertyValue("crmContactMaxLength"),
    });
    obj.setPropertyValue("contactMap", value);
  }
}

export default SetContactMaxLengthAndValue;

import i18next from "../localization/i18n";

const Preference = [
  { value: null },
  { value: 0, text: i18next.t("EDITORS_COMMON.PREFERENCES.IN") },
  { value: 1, text: i18next.t("EDITORS_COMMON.PREFERENCES.OUT") },
  { value: 2, text: i18next.t("EDITORS_COMMON.PREFERENCES.NO_PREFERENCE") },
];

export default Preference;

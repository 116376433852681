import React, { useEffect } from "react";
import "../App.css";
import { ErrorBoundary } from "react-error-boundary";
import { LoadingProvider } from "../common/providers/LoadingSpinner";
import FormCreator from "./components/FormCreator";
import FallbackRender from "../common/functions/FallbackRender";

function FormEditor({ title }: any) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div className="App">
      <LoadingProvider>
        <ErrorBoundary fallbackRender={FallbackRender}>
          <FormCreator />
        </ErrorBoundary>
      </LoadingProvider>
    </div>
  );
}

export default FormEditor;

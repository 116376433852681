import { useState } from "react";

const useThrowAsyncError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState();

  return (error: any) => {
    setState(() => {
      throw error;
    });
  };
};

export default useThrowAsyncError;

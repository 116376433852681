import { useEffect, useState } from "react";
import { useLoading } from "../../common/providers/LoadingSpinner";
import useThrowAsyncError from "../../common/hooks/useThrowAsyncError";
import Spinner from "../../common/components/Spinner";
import SubscriptionCreatorWidget from "./SubscriptionCreatorWidget";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import GetSubscriptionsJson from "../api/GetSubscriptionsJson";

function SubscriptionCreator() {
  const [json, setJson] = useState<null>(null);
  const { loading, setLoading } = useLoading();
  const throwAsyncError = useThrowAsyncError();
  const [subscriptionsStateParams, setSubscriptionsStateParams] =
    useState<SubscriptionsStateParams>({
      accountKey: "",
      orgname: "",
      sessionId: "",
      userid: "",
      userlcid: "",
      webContentId: "",
      supportedCRM: SupportedCRM.MSCRM,
      isModified: false,
      isProduction: false,
      isFirstLoad: true,
      isOptIn: false,
    });

  const searchParams = new URLSearchParams(window.location.search);

  const subscriptionsParams: SubscriptionsStateParams = {
    orgname: searchParams.get("orgname"),
    userid: searchParams.get("userid"),
    accountKey: searchParams.get("accountKey"),
    sessionId: searchParams.get("sessionId"),
    userlcid: searchParams.get("userlcid"),
    webContentId: searchParams.get("id"),
    isProduction:
      process.env.REACT_APP_IS_PRODUCTION !== undefined
        ? JSON.parse(process.env.REACT_APP_IS_PRODUCTION.toString().toLowerCase())
        : false,
  } as SubscriptionsStateParams;
  useEffect(() => {
    if (subscriptionsParams.webContentId !== null) {
      // @ts-ignore
      setLoading(true);
      setSubscriptionsStateParams(Object.assign(subscriptionsStateParams, subscriptionsParams));
      GetSubscriptionsJson(subscriptionsStateParams)!
        .then((data: any) => {
          subscriptionsStateParams.supportedCRM = data.supportedCRM;
          subscriptionsStateParams.isOptIn = data.isOptIn;
          setJson(data);
          // @ts-ignore
          setLoading(false);
        })
        .catch(async (e) => {
          throwAsyncError(e);
          // @ts-ignore
          setLoading(false);
        });
    } else {
      throwAsyncError("");
    }
  }, []);

  if (json === null) {
    return <span className="survey-loader" />;
  }

  return (
    <>
      {loading && <Spinner />}
      <SubscriptionCreatorWidget
        subscriptionsEditorModel={json}
        subscriptionsStateParams={subscriptionsStateParams}
      />
    </>
  );
}

export default SubscriptionCreator;

import { Serializer } from "survey-core";

function SurveySerializer() {
  Serializer.getProperty("survey", "questionsOnPageMode").visible = false;
  Serializer.getProperty("survey", "firstPageIsStarted").visible = false;
  Serializer.getProperty("survey", "goNextPageAutomatic").visible = false;
  Serializer.getProperty("survey", "showNavigationButtons").visible = false;
  Serializer.getProperty("survey", "showPrevButton").visible = false;
  Serializer.getProperty("survey", "showTOC").visible = false;
  Serializer.getProperty("survey", "pagePrevText").visible = false;
  Serializer.getProperty("survey", "pageNextText").visible = false;
  Serializer.getProperty("survey", "showPreviewBeforeComplete").visible = false;
  Serializer.getProperty("survey", "showPageTitles").visible = false;
  Serializer.getProperty("survey", "showPageNumbers").visible = false;
  Serializer.getProperty("survey", "completedBeforeHtml").visible = false;
}

export default SurveySerializer;

import * as SurveyCore from "survey-core";
import { MapType } from "../enums/MapType";
import SetLeadMaxLengthAndValue from "./common/SetLeadMaxLengthAndValue";
import SetContactMaxLengthAndValue from "./common/SetContactMaxLengthAndValue";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import GetLeadContactAttributeOptions from "../../common/api/GetLeadContactAttributeOptions";
import SetMapperAttributes from "../../common/helpers/SetMapperAttributes";
import { EntityType } from "../../common/enums/EntityType";
import SetItemValueChoicesProperty from "../../common/helpers/SetItemValueChoicesProperty";
import {
  SetContactChoices,
  SetLeadChoices,
} from "../../common/component-collection/ItemValueCommon";

function MultiselectToolbox(formStateParams: FormStateParams, dialog: any) {
  if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
    SurveyCore.Serializer.addProperty("tagbox", {
      name: "crmMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("tagbox", {
      name: "crmContactMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("tagbox", {
      name: "crmLeadMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("tagbox", {
      name: "contactMap",
      isSerializable: true,
      visible: false,
      categoryIndex: 8,
      onSetValue: (obj: any, value: any) => {
        if (obj.isDesignMode) {
          if (
            !obj.getPropertyValue("contactMapTemp") &&
            obj.getPropertyValue("mapType") === MapType["text field"]
          ) {
            SetContactMaxLengthAndValue(obj, value);
          } else {
            obj.setPropertyValue("contactMap", value);
          }
        }
      },
    });

    if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
      SurveyCore.Serializer.addProperty("tagbox", {
        name: "leadMap",
        isSerializable: true,
        visible: false,
        categoryIndex: 8,
        onSetValue: (obj: any, value: any) => {
          if (obj.isDesignMode) {
            if (
              !obj.getPropertyValue("leadMapTemp") &&
              obj.getPropertyValue("mapType") === MapType["text field"]
            ) {
              SetLeadMaxLengthAndValue(obj, value);
            } else {
              obj.setPropertyValue("leadMap", value);
            }
          }
        },
      });

      SurveyCore.Serializer.addProperty("tagbox", {
        name: "leadMapTemp",
        displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
        isRequired: false,
        category: "toolboxMapperCategory",
        type: "dropdown",
        categoryIndex: 8,
        isSerializable: false,
        dependsOn: ["mapType"],
        onPropertyEditorUpdate: (obj: any, editor: any) => {
          editor.allowClear = true;
        },
        onSetValue: (surveyElement: any, value: any) => {
          if (surveyElement.mapType === undefined) return;
          if (surveyElement.isDesignMode) {
            if (MapType[surveyElement.mapType] === MapType[1]) {
              GetLeadContactAttributeOptions(formStateParams, EntityType.Lead, value)?.then(
                (data: any) => {
                  SetItemValueChoicesProperty(
                    data,
                    surveyElement,
                    value,
                    SetLeadChoices,
                    formStateParams,
                    ["leadAttributeOptionMap"],
                    dialog,
                  );
                },
              );
              surveyElement.setPropertyValue("leadMap", value);
              surveyElement.setPropertyValue("leadMapTemp", value);
            } else {
              SetLeadChoices("textMap", []);
              SetLeadMaxLengthAndValue(surveyElement, value);
            }
          }
        },
        choices: (obj: any, choicesCallback: any) => {
          if (!choicesCallback) return;
          obj.clearErrors();
          if (MapType[obj.mapType] === MapType[0]) {
            SetMapperAttributes("text", choicesCallback, formStateParams, EntityType.Lead, obj);
          }
          if (MapType[obj.mapType] === MapType[1]) {
            SetMapperAttributes(
              "multiList",
              choicesCallback,
              formStateParams,
              EntityType.Lead,
              obj,
            );
          }
        },
        visibleIndex: 1,
      });
    }
  }

  SurveyCore.Serializer.addProperty("tagbox", {
    name: "contactMapTemp",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "dropdown",
    isSerializable: false,
    dependsOn: ["mapType"],
    categoryIndex: 8,
    onPropertyEditorUpdate: (obj: any, editor: any) => {
      editor.allowClear = true;
    },
    onSetValue: (surveyElement: any, value: any) => {
      if (surveyElement.mapType === undefined) return;
      if (surveyElement.isDesignMode) {
        if (MapType[surveyElement.mapType] === MapType[1]) {
          GetLeadContactAttributeOptions(formStateParams, EntityType.Contact, value)?.then(
            (data: any) => {
              SetItemValueChoicesProperty(
                data,
                surveyElement,
                value,
                SetContactChoices,
                formStateParams,
                ["contactAttributeOptionMap"],
                dialog,
              );
            },
          );
          surveyElement.setPropertyValue("contactMap", value);
          surveyElement.setPropertyValue("contactMapTemp", value);
        } else {
          SetContactChoices("textMap", []);
          SetContactMaxLengthAndValue(surveyElement, value);
        }
      }
    },
    choices: (obj: any, choicesCallback: any) => {
      if (!choicesCallback) return;
      if (MapType[obj.mapType] === MapType[0]) {
        SetMapperAttributes("text", choicesCallback, formStateParams, EntityType.Contact);
      }
      if (MapType[obj.mapType] === MapType[1]) {
        SetMapperAttributes("multiList", choicesCallback, formStateParams, EntityType.Contact);
      }
    },
    visibleIndex: 1,
  });

  SurveyCore.Serializer.addProperty("tagbox", {
    name: "overrideInCrmMap",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "boolean",
    categoryIndex: 8,
  });

  SurveyCore.Serializer.addProperty("tagbox", {
    name: "mapType",
    displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.MAP_SWITCHER_TEXT"),
    isRequired: false,
    category: "toolboxMapperCategory",
    type: "checkbox",
    visibleIndex: 0,
    isSerializable: true,
    isLightSerializable: true,
    categoryIndex: 8,
    choices: [
      { text: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.MAP_SWITCHER_LIST_FIELD"), value: 1 },
      { text: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.MAP_SWITCHER_TEXT_FIELD"), value: 0 },
    ],
    onSetValue: (surveyElement: any, value: any) => {
      if (!surveyElement.isDesignMode) {
        return;
      }
      if (surveyElement.mapType !== undefined) {
        surveyElement.choices = null;
        surveyElement.setPropertyValue("contactMap", null);
        surveyElement.setPropertyValue("contactMapTemp", null);
        surveyElement.setPropertyValue("leadMap", null);
        surveyElement.setPropertyValue("leadMapTemp", null);
      }
      surveyElement.setPropertyValue("mapType", value);
      SetContactChoices("textMap", []);
      SetLeadChoices("textMap", []);
      if (MapType[value] === MapType[0]) {
        if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
          SurveyCore.Serializer.findClass("itemvalue").findProperty(
            "leadAttributeOptionMap",
          ).visible = false;
        }
        SurveyCore.Serializer.findClass("itemvalue").findProperty(
          "contactAttributeOptionMap",
        ).visible = false;
        if (
          !surveyElement.getPropertyValue("leadMapTemp") ||
          !surveyElement.getPropertyValue("contactMapTemp")
        ) {
          SetLeadMaxLengthAndValue(surveyElement, surveyElement.getPropertyValue("leadMap"));
          SetContactMaxLengthAndValue(surveyElement, surveyElement.getPropertyValue("contactMap"));
        }
      } else {
        if (!surveyElement.getPropertyValue("contactMapTemp")) {
          surveyElement
            .getPropertyByName("contactMapTemp")
            .setValue(surveyElement, surveyElement.getPropertyValue("contactMap"));
        }
        surveyElement.setPropertyValue("crmContactMaxLength", null);
        surveyElement.setPropertyValue("crmLeadMaxLength", null);
        surveyElement.setPropertyValue("crmMaxLength", null);

        if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
          if (!surveyElement.getPropertyValue("leadMapTemp")) {
            surveyElement
              .getPropertyByName("leadMapTemp")
              .setValue(surveyElement, surveyElement.getPropertyValue("leadMap"));
          }
          SurveyCore.Serializer.findClass("itemvalue").findProperty(
            "leadAttributeOptionMap",
          ).visible = true;
        }
        SurveyCore.Serializer.findClass("itemvalue").findProperty(
          "contactAttributeOptionMap",
        ).visible = true;
      }
      surveyElement.setPropertyValue("mapType", value);
    },
  });
}

export default MultiselectToolbox;

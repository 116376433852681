import React, { useEffect, useMemo, useState } from "react";
import * as SurveyCreator from "survey-creator-react";
import * as SurveyCore from "survey-core";
import "survey-core/defaultV2.css";
import "../../common/styles/defaultV2_custom.css";
import "survey-creator-core/survey-creator-core.css";
import "../../common/styles/survey-creator-core_custom.css";
// @ts-ignore
import * as widgets from "surveyjs-widgets";
import "survey-core/survey.i18n";
import "survey-creator-core/survey-creator-core.i18n";
import $ from "jquery";
import { useBeforeunload } from "react-beforeunload";
import OnQuestionAdded from "../functions/OnQuestionAdded";
import CheckboxToolbox from "../component-collection/Checkbox";
import SurveySerializer from "../survey-customization/SurveySerializer";
import ItemValue from "../component-collection/ItemValue";
import ListToolbox from "../component-collection/List";
import RadioGroupToolbox from "../component-collection/Radiogroup";
import ChangeToolboxCategories from "../survey-customization/ChangeToolboxCategories";
import OrderToolbox from "../survey-customization/OrderToolbox";
import { defaultJson, surveyOptions } from "../survey-customization/SurveyOptions";
import OnElementDeleting from "../functions/OnElementDeleting";
import SingleInput from "../component-collection/SingleInput";
import Icons from "../survey-customization/Icons";
import BooleanToolbox from "../component-collection/Boolean";
// eslint-disable-next-line import/extensions
import "jquery-bar-rating/jquery.barrating.js";
import "jquery-ui-dist/jquery-ui.css";
import CommentToolbox from "../component-collection/Comment";
import OnModified from "../functions/OnModified";
import OnShowingProperty from "../functions/OnShowingProperty";
import SurveyLocalization from "../survey-customization/SurveyLocalization";
import Multiselect from "../component-collection/Multiselect";
import { DateTimeControl } from "../component-collection/DateTimeControl";
import PhoneToolbox from "../component-collection/Phone";
import CountryToolbox from "../component-collection/Country";
import SaveSurveyFunc from "../functions/SaveSurveyFunc";
import SubscriptionList from "../component-collection/SubscriptionList";
import Settings from "../survey-customization/Settings";
import OnSurveyPropertyValueChanged from "../functions/OnSurveyPropertyValueChanged";
import CommonTopBarDropdown from "../../common/buttons/TopBarDropdown";
import IFormEditorCreatorProps from "../interfaces/IFormEditorCreatorProps";
import useDialog from "../../common/hooks/useDialog";
import Spinner from "../../common/components/Spinner";
import { useLoading } from "../../common/providers/LoadingSpinner";
import SetLicenseKey from "../../common/functions/SetLicenseKey";
import SetTheme from "../../common/functions/SetTheme";
import Consent from "../../common/component-collection/Consent";
import i18next from "../../common/localization/i18n";
import IconsCommon from "../../common/survey-customization/IconsCommon";
import SetLocale from "../../common/functions/SetLocale";
import localeStringsEn from "../localization/FormEditorEn";
import localeStringsFR from "../localization/FormEditorFr";
import SaveButton from "../../common/buttons/SaveButton";
import CustomizeToolbar from "../../common/survey-customization/CustomizeToolbar";
import ThemeConfiguration from "../../common/survey-customization/ThemeConfiguration";
import OnUploadFile from "../../common/functions/OnUploadFile";
import SettingsPanel from "../../common/survey-customization/SettingsPanel";
import OnSurveyInstanceCreated from "../../common/functions/OnSurveyInstanceCreated";
import SurveySerializerCommon from "../../common/survey-customization/SurveySerializerCommon";
import PropertyGridEditorCollection from "../../common/survey-customization/PropertyGridEditorCollection";
import CkEditor from "../../common/external-components/CkEditor";
import { DropdownItemID } from "../../common/enums/DropdownItem";

// @ts-ignore
// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = $;
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
require("jquery-ui-dist/jquery-ui.js");

widgets.inputmask(SurveyCore);

function FormCreatorWidget({ formModel, formStateParams }: IFormEditorCreatorProps) {
  const { dialog } = useDialog();

  const [creator, setCreator] = useState<SurveyCreator.SurveyCreator>();
  const { loading, setLoading } = useLoading();
  const [isModified, setIsModified] = useState("");
  let surveyCreator: SurveyCreator.SurveyCreator;
  SetLocale(formStateParams, localeStringsEn, localeStringsFR);

  // @ts-ignore
  useBeforeunload(isModified !== "" ? (event) => event.preventDefault() : null);
  SetLicenseKey();
  ItemValue(formStateParams);
  SurveySerializer();
  SurveySerializerCommon();
  CheckboxToolbox(formStateParams, dialog);
  RadioGroupToolbox(formStateParams, dialog);
  ListToolbox(formStateParams, dialog);
  BooleanToolbox(formStateParams);
  SingleInput(formStateParams);
  CommentToolbox(formStateParams);
  Multiselect(formStateParams, dialog);
  Icons();
  IconsCommon();
  SettingsPanel();
  DateTimeControl(formStateParams);
  PhoneToolbox(formStateParams);
  CountryToolbox(formStateParams);
  SubscriptionList(formStateParams);
  Consent(formStateParams, dialog, i18next.t("NEW_FORM_EDITOR.CONSENT.CONSENT"));
  Settings();
  PropertyGridEditorCollection();
  CkEditor();

  if (creator === undefined) {
    surveyOptions.showJSONEditorTab = !formStateParams.isProduction;
    surveyCreator = new SurveyCreator.SurveyCreator(surveyOptions);

    ThemeConfiguration(surveyCreator);

    surveyCreator.onUploadFile.add((sender: any, options: any) => {
      OnUploadFile(sender, options);
    });
    SurveyLocalization(formStateParams);
    CustomizeToolbar(surveyCreator);

    surveyCreator.onSurveyInstanceCreated.add((sender: any, options: any) => {
      OnSurveyInstanceCreated(sender, options);
    });

    if (formModel && formModel.json) {
      surveyCreator.JSON = JSON.parse(formModel.json);
    } else {
      surveyCreator.JSON = defaultJson;
    }

    surveyCreator.saveSurveyFunc = () => {
      SaveSurveyFunc(surveyCreator, formStateParams, setLoading, formModel, dialog, setIsModified);
    };

    surveyCreator.saveThemeFunc = () => {
      surveyCreator.saveSurvey();
    };

    surveyCreator.onShowingProperty.add((sender: any, options: any) => {
      OnShowingProperty(sender, options, formStateParams);
    });

    SetTheme(surveyCreator, formModel);
    ChangeToolboxCategories(surveyCreator);
    OrderToolbox(surveyCreator);
    OnElementDeleting(surveyCreator, dialog);
    CommonTopBarDropdown(
      surveyCreator,
      formStateParams,
      setLoading,
      dialog,
      DropdownItemID.CheckMappings,
    );
    SaveButton(surveyCreator);

    surveyCreator.onQuestionAdded.add((sender: any, options: any) =>
      OnQuestionAdded(sender, options),
    );

    setCreator(surveyCreator);

    surveyCreator.onStateChanged.add((sender: any, options: any) => {
      setIsModified(options);
    });
    surveyCreator.onModified.add((sender: any, options: any) => {
      setIsModified(options);
      OnModified(sender, options);
    });
    surveyCreator.onSurveyPropertyValueChanged.add((sender: any, options: any) => {
      OnSurveyPropertyValueChanged(sender, options);
    });
  }

  return (
    <div className="form-creator-widget">
      {loading && <Spinner />}
      {creator === undefined ? <div /> : <SurveyCreator.SurveyCreatorComponent creator={creator} />}
    </div>
  );
}

export default FormCreatorWidget;

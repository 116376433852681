import * as SurveyCore from "survey-core";
import PublishCustomFieldsButton from "./PublishCustomFieldsButton";
import CheckUnmappedFieldsButton from "./CheckUnmappedFieldsButton";
import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import { DropdownItemID } from "../enums/DropdownItem";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";

export default function TopBarDropdown(
  surveyCreator: any,
  stateParams: FormStateParams | SubscriptionsStateParams,
  setLoading: any,
  dialog: any,
  items: any,
) {
  const dropdownItems: SurveyCore.IAction[] = [];

  dropdownItems.push({ title: "Publish Custom Fields", id: DropdownItemID.PublishCustomFields });

  if (items === DropdownItemID.CheckMappings) {
    dropdownItems.push({ title: "Check Mapping", id: DropdownItemID.CheckMappings });
  }

  // @ts-ignore
  const isVisible: boolean = new SurveyCore.ComputedUpdater<boolean | undefined>(() => {
    return surveyCreator.activeTab === "designer";
  });

  const customDropdownAction = SurveyCore.createDropdownActionModel(
    {
      id: "customDropdownAction",
      iconName: "icon-vertical-three-dots",
      visible: isVisible,
    },
    {
      items: dropdownItems,
      onSelectionChanged: (item) => {
        if (item.id === DropdownItemID.PublishCustomFields) {
          PublishCustomFieldsButton(surveyCreator, stateParams, setLoading, dialog);
        }
        if (item.id === DropdownItemID.CheckMappings) {
          CheckUnmappedFieldsButton(surveyCreator, dialog);
        }
      },
      verticalPosition: "bottom",
      horizontalPosition: "center",
    },
  );
  surveyCreator.toolbar.actions.push(customDropdownAction);
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./common/localization/i18n";
import DialogContextProvider from "./common/providers/DialogContextProvider";
import Dialog from "./common/components/Dialog";
import FormEditor from "./form-editor/FormEditor";
import SubscriptionsEditor from "./subscription-editor/SubscriptionsEditor";

const router = createBrowserRouter([
  {
    path: "/form",
    element: <FormEditor title="Form Editor" />,
  },
  {
    path: "/subscriptions",
    element: <SubscriptionsEditor title="Preference Center Designer" />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <DialogContextProvider>
      <RouterProvider router={router} />
      <Dialog />
    </DialogContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Guid from "../../common/helpers/Guid";
import { RegisterPhoneValidators } from "../../common/validators/PhoneValidators";
import SetQuestionNameAndTitle from "../../common/helpers/SetQuestionNameAndTitle";

function getChoices(options: any) {
  const newChoices = [];
  if (options.reason === "ELEMENT_COPIED")
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < options.question.choices.length; i++) {
      const choice = options.question.choices[i];
      choice.contactAttributeOptionMap = null;
      choice.leadAttributeOptionMap = null;
      newChoices.push(choice);
    }
  return newChoices;
}
function OnQuestionAdded(sender: any, options: any) {
  const { question } = options;
  const questionType = question.getType();
  question.hideNumber = true;
  switch (questionType) {
    case "email_field":
      question.crmFieldType = "email";
      break;
    case "phone_field":
      question.crmFieldType = "phone";
      question.leadMap = null;
      question.contactMap = null;
      RegisterPhoneValidators(question);
      break;
    case "radiogroup":
      question.crmFieldType = "dropdown";
      question.choices = getChoices(options);
      question.leadMap = null;
      question.contactMap = null;
      break;
    case "checkbox":
      question.crmFieldType = "multiselect";
      question.choices = getChoices(options);
      question.mapType = 1;
      question.leadMap = null;
      question.contactMap = null;
      break;
    case "tagbox":
      question.crmFieldType = "multiselect";
      question.mapType = 1;
      question.choices = getChoices(options);
      question.leadMap = null;
      question.contactMap = null;
      break;
    case "boolean":
      question.crmFieldType = "checkbox";
      question.defaultValue = "false";
      question.leadMap = null;
      question.contactMap = null;
      question.toggleState = false;
      break;
    case "dropdown":
      question.crmFieldType = "dropdown";
      question.mapType = 1;
      question.choices = getChoices(options);
      question.leadMap = null;
      question.contactMap = null;
      break;
    case "comment":
      question.crmFieldType = "textarea";
      break;
    case "datetimepicker":
      question.dateFormat = "MM/dd/yyyy";
      question.crmFieldType = "date";
      question.dateTimePickerType = "Date";
      break;
    case "subscription_list":
      question.crmFieldType = "subscriptionlist";
      question.choices = getChoices(options);
      question.leadMap = null;
      question.contactMap = null;
      break;
    case "consent":
      question.crmFieldType = "consent";
      question.choices = getChoices(options);
      question.leadMap = null;
      question.contactMap = null;
      question.titleLocation = "hidden";
      break;
    default:
      question.crmFieldType = questionType;
  }

  question.clearIfInvisible = "none";
  question.overrideInCrmMap = false;
  question.formFieldId = Guid();
  question.crmId = null;

  SetQuestionNameAndTitle(sender, options, "NEW_FORM_EDITOR", questionType);
}

export default OnQuestionAdded;

import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import PhoneComponent from "../../common/component-collection/PhoneComponent";

export default function Phone(subscriptionStateParams: SubscriptionsStateParams) {
  if (!SurveyCore.Serializer.findClass("phone_field") && subscriptionStateParams.isOptIn) {
    PhoneComponent(i18next.t("NEW_SUBSCRIPTIONS_EDITOR.PHONE.PHONE"));
  }
}

import Guid from "../../common/helpers/Guid";

export const surveyOptions = {
  showLogicTab: true,
  isAutoSave: false,
  syncSaveButtons: true,
  showTranslationTab: false,
  showJSONEditorTab: false,
  showThemeTab: true,
  logoPosition: "right",
  questionTypes: [
    "text",
    "datetimepicker",
    "checkbox",
    "comment",
    "dropdown",
    "phone_field",
    "file",
    "image",
    "radiogroup",
    "boolean",
    "tagbox",
    "html",
  ],
  clearInvisibleValues: "none",
};

export const defaultJson = {
  captcha: true,
  logoPosition: "right",
  pages: [
    {
      elements: [
        {
          type: "text",
          inputType: "email",
          name: "Single Input 1",
          title: "Single Input 1",
          formFieldId: Guid(),
          crmFieldType: "email",
          inputMask: "email",
          overrideInCrmMap: false,
          hideNumber: true,
          clearIfInvisible: "none",
        },
      ],
    },
  ],
  clearInvisibleValues: "none",
};

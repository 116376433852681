// @ts-ignore
// eslint-disable-next-line react/prop-types
import React from "react";
import i18next from "../localization/i18n";

// @ts-ignore
// eslint-disable-next-line react/prop-types,@typescript-eslint/no-unused-vars
function FallbackRender({ error, resetErrorBoundary }) {
  let errorMessage = i18next.t("NEW_FORM_EDITOR.FALLBACK_RENDERER.UNEXPECTED_ERROR");
  // eslint-disable-next-line react/prop-types
  if (error.message === "Unauthorized") {
    errorMessage = i18next.t("NEW_FORM_EDITOR.FALLBACK_RENDERER.UNAUTHORIZED_ERROR");
  }
  // eslint-disable-next-line react/prop-types
  if (error.message === "Internal Server Error") {
    errorMessage = i18next.t("NEW_FORM_EDITOR.FALLBACK_RENDERER.SERVER_ERROR");
  }
  return (
    <div className="portal-overlay">
      <div className="confirm-dialog">
        <div className="header">{i18next.t("NEW_FORM_EDITOR.FALLBACK_RENDERER.ERROR")}</div>
        {/* eslint-disable-next-line react/prop-types */}
        <p className="body">{errorMessage}</p>
        <div className="confirm-dialog__footer">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="btn"
            onClick={() => {
              resetErrorBoundary();
            }}
          >
            {i18next.t("NEW_FORM_EDITOR.FALLBACK_RENDERER.RETRY")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FallbackRender;

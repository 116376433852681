import * as SurveyCore from "survey-core";
import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import i18next from "../../common/localization/i18n";

export default function GlobalUnsubscribeSms(subscriptionStateParams: SubscriptionsStateParams) {
  if (
    !SurveyCore.Serializer.findClass("global_unsubscribe_sms") &&
    subscriptionStateParams.isOptIn
  ) {
    SurveyCore.ComponentCollection.Instance.add({
      name: "global_unsubscribe_sms",
      title: i18next.t("NEW_SUBSCRIPTIONS_EDITOR.UNSUBSCRIBE_ALL_SMS.TITLE"),
      iconName: "icon-global-unsubscribe-sms",
      questionJSON: {
        type: "checkbox",
        titleLocation: "hidden",
        choices: [
          i18next.t("NEW_SUBSCRIPTIONS_EDITOR.UNSUBSCRIBE_ALL_SMS.CHOICES.UNSUBSCRIBE_ALL_SMS"),
        ],
      },
    });
  }
}

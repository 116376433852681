import * as SurveyCore from "survey-core";
import SetLeadMaxLengthAndValue from "./common/SetLeadMaxLengthAndValue";
import SetContactMaxLengthAndValue from "./common/SetContactMaxLengthAndValue";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";
import { SupportedCRM } from "../../common/enums/SupportedCRM";
import PhoneComponent from "../../common/component-collection/PhoneComponent";
import SetMapperAttributes from "../../common/helpers/SetMapperAttributes";
import { EntityType } from "../../common/enums/EntityType";

function PhoneToolbox(formStateParams: FormStateParams) {
  if (!SurveyCore.Serializer.findClass("phone_field")) {
    PhoneComponent(i18next.t("NEW_FORM_EDITOR.PHONE.PHONE"));

    SurveyCore.Serializer.addProperty("phone_field", {
      name: "crmMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("phone_field", {
      name: "crmContactMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("phone_field", {
      name: "crmLeadMaxLength",
      type: "number",
      default: 1,
      isSerializable: true,
      visible: false,
    });
    SurveyCore.Serializer.addProperty("phone_field", {
      name: "contactMap",
      isSerializable: true,
      visible: false,
      categoryIndex: 8,
      onSetValue: (obj: any, value: any) => {
        if (!obj.getPropertyValue("contactMapTemp")) {
          SetContactMaxLengthAndValue(obj, value);
        }
      },
    });
    SurveyCore.Serializer.addProperty("phone_field", {
      name: "leadMap",
      isSerializable: true,
      visible: false,
      categoryIndex: 8,
      onSetValue: (obj: any, value: any) => {
        if (!obj.getPropertyValue("leadMapTemp")) {
          SetLeadMaxLengthAndValue(obj, value);
        }
      },
    });

    SurveyCore.Serializer.addProperty("phone_field", {
      name: "contactMapTemp",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.CONTACT_MAP"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "dropdown",
      isSerializable: false,
      onPropertyEditorUpdate: (obj: any, editor: any) => {
        editor.allowClear = true;
      },
      onSetValue: (surveyElement: any, value: any) => {
        SetContactMaxLengthAndValue(surveyElement, value);
      },
      choices: (obj: any, choicesCallback: any) => {
        if (!choicesCallback) return;
        obj.clearErrors();
        SetMapperAttributes("phone", choicesCallback, formStateParams, EntityType.Contact, obj);
      },
      visibleIndex: 1,
      categoryIndex: 8,
    });

    if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
      SurveyCore.Serializer.addProperty("phone_field", {
        name: "leadMapTemp",
        displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.LEAD_MAP"),
        isRequired: false,
        category: "toolboxMapperCategory",
        type: "dropdown",
        isSerializable: false,
        onPropertyEditorUpdate: (obj: any, editor: any) => {
          editor.allowClear = true;
        },
        onSetValue: (surveyElement: any, value: any) => {
          SetLeadMaxLengthAndValue(surveyElement, value);
        },
        choices: (obj: any, choicesCallback: any) => {
          if (!choicesCallback) return;
          obj.clearErrors();
          SetMapperAttributes("phone", choicesCallback, formStateParams, EntityType.Lead, obj);
        },
        visibleIndex: 1,
        categoryIndex: 8,
      });
    }

    SurveyCore.Serializer.addProperty("phone_field", {
      name: "overrideInCrmMap",
      displayName: i18next.t("NEW_FORM_EDITOR.COMPONENT_COMMON.OVERRIDE_IN_CRM"),
      isRequired: false,
      category: "toolboxMapperCategory",
      type: "boolean",
      visibleIndex: 2,
      categoryIndex: 8,
    });
  }
}

export default PhoneToolbox;

import { SvgRegistry } from "survey-core";

export default function Icons() {
  SvgRegistry.registerIconFromSvg(
    "email",
    `<svg viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
    <path d="M16 3V13H0V3H16ZM1.11719 4L8 7.44531L14.8828 4H1.11719ZM15 12V5.0625L8 8.55469L1 5.0625V12H15Z" />
  </svg>`,
  );

  SvgRegistry.registerIconFromSvg(
    "global-unsubscribe-sms",
    `<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_154_387)">
      <path d="M31.4816 47H24V51.0119H31.4816V47Z" />
      <path d="M45.6662 53.8891L51.8077 48.0746L49.0309 45.1416L42.8893 50.9561L37.0747 44.8146L34.1418 47.5914L39.9563 53.733L33.8148 59.5476L36.5916 62.4806L42.7332 56.666L48.5478 62.8075L51.4807 60.0307L45.6662 53.8891Z" />
      <path d="M12.2592 59.7023C11.8 59.4907 11.4055 59.2197 11.0772 58.8895C10.7479 58.5583 10.4866 58.1689 10.2938 57.7229L10.2831 57.6979C10.0957 57.2549 10.0022 56.7753 10.0022 56.2611L10.0022 7.74376L10.0024 7.7493C9.98287 7.23738 10.081 6.7439 10.2959 6.27221C10.5064 5.81039 10.7758 5.41361 11.1041 5.08341C11.4334 4.75224 11.8205 4.48939 12.264 4.29548L12.2889 4.28471C12.7293 4.09628 13.2062 4.00225 13.7174 4.00225L41.2808 4.00225L41.2745 4.00245C41.7835 3.98276 42.2741 4.08146 42.7431 4.29765C43.2023 4.50932 43.5968 4.78028 43.9251 5.11049C44.2543 5.44165 44.5157 5.83106 44.7085 6.27707C44.903 6.72699 45 7.21504 45 7.73889L45 42.1599L41.0156 42.1599L41.0156 8.00966L13.9866 8.00966L13.9866 55.9904L31 55.9904L31 59.9978L13.7222 59.9977L13.7277 59.9975C13.2188 60.0172 12.7281 59.9185 12.2592 59.7023Z" />
      </g>
      <defs>
      <clipPath id="clip0_154_387">
      </clipPath>
      </defs>
    </svg>`,
  );

  SvgRegistry.registerIconFromSvg(
    "global-unsubscribe-email",
    `<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M64 12V40H60V20.25L32 34.2188L4 20.25V48H40V52H0V12H64ZM32 29.7812L59.5312 16H4.46875L32 29.7812ZM63.9062 46.9375L56.8438 54L63.9062 61.0938L61.0938 63.9062L54 56.8438L46.9062 63.9375L44.0938 61.0938L51.1875 54L44.0938 46.9062L46.9062 44.0938L54 51.1875L61.0938 44.0938L63.9062 46.9375Z"/>
    </svg>`,
  );
}

import * as SurveyCore from "survey-core";
import i18next from "../../common/localization/i18n";
import FormStateParams from "../interfaces/FormStateParams";

export default function SubscriptionList(formStateParams: FormStateParams) {
  if (!SurveyCore.Serializer.findClass("subscription_list") && formStateParams.isOptIn) {
    const updateChoices = (question: any) => {
      const subscriptionList = question.contentPanel.getQuestionByName("subscriptionList");
      if (question.choices.length > 0) {
        subscriptionList.choices = question.choices.map(
          (val: any) => new SurveyCore.ItemValue(val.value, val.text),
        );
      }
      if (question.choices.length === 0) {
        subscriptionList.choices = question.choices.push(
          new SurveyCore.ItemValue("item1", "item1"),
        );
        question.propertyValueChanged("choices");
      }
    };

    SurveyCore.ComponentCollection.Instance.add({
      name: "subscription_list",
      title: i18next.t("NEW_FORM_EDITOR.SUBSCRIPTION_LIST.SUBSCRIPTION_LIST"),
      iconName: "icon-subscriptions",
      elementsJSON: [
        {
          name: "subscriptionList",
          type: "checkbox",
          titleLocation: "hidden",
          choices: [],
        },
      ],
      onInit: () => {
        SurveyCore.Serializer.addProperty("subscription_list", {
          name: "choices",
          category: "Choices",
          type: "itemvalues",
          isSerializable: true,
          visible: true,
        });
      },
      onLoaded: (question) => {
        // @ts-ignore
        updateChoices(question);
      },
      onPropertyChanged: (question, propertyName) => {
        if (propertyName === "choices") {
          // @ts-ignore
          updateChoices(question);
        }
      },
      onItemValuePropertyChanged: (question, options) => {
        if (options.propertyName === "choices") {
          // @ts-ignore
          updateChoices(question);
        }
      },
    });
  }
}

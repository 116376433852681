import * as SurveyCore from "survey-core";
import FormStateParams from "../interfaces/FormStateParams";
import { SupportedCRM } from "../../common/enums/SupportedCRM";

const propertyStopList = ["startSurveyText"];

function OnShowingProperty(sender: any, options: any, formStateParams: FormStateParams) {
  options.canShow = propertyStopList.indexOf(options.property.name) === -1;

  if (options.property.name === "mapType") {
    if (options.obj.mapType === 0) {
      SurveyCore.Serializer.findClass("itemvalue").findProperty(
        "contactAttributeOptionMap",
      ).visible = false;
      if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
        SurveyCore.Serializer.findClass("itemvalue").findProperty(
          "leadAttributeOptionMap",
        ).visible = false;
      }
    } else {
      SurveyCore.Serializer.findClass("itemvalue").findProperty(
        "contactAttributeOptionMap",
      ).visible = true;
      if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
        SurveyCore.Serializer.findClass("itemvalue").findProperty(
          "leadAttributeOptionMap",
        ).visible = true;
      }
    }
  }

  if (
    options.property.name === "overrideInCrmMap" &&
    (options.property.classInfoValue.name === "checkbox" ||
      options.property.classInfoValue.name === "radiogroup")
  ) {
    SurveyCore.Serializer.findClass("itemvalue").findProperty("contactAttributeOptionMap").visible =
      true;
    if (formStateParams.supportedCRM === SupportedCRM.MSCRM) {
      SurveyCore.Serializer.findClass("itemvalue").findProperty("leadAttributeOptionMap").visible =
        true;
    }
  }

  if (options.property.name === "subscriptionListMap") {
    options.canShow = false;
  }
  if (options.property.name === "trueMap") {
    options.canShow = false;
  }
  if (options.property.name === "falseMap") {
    options.canShow = false;
  }
  if (options.property.name === "leadChoiceMap") {
    options.canShow = false;
  }
  if (options.property.name === "contactChoiceMap") {
    options.canShow = false;
  }
  if (options.property.name === "subscriptionType") {
    options.canShow = false;
  }
  if (options.property.name === "contactTrueMap") {
    options.canShow = false;
  }
  if (options.property.name === "contactFalseMap") {
    options.canShow = false;
  }
  if (options.property.name === "leadTrueMap") {
    options.canShow = false;
  }
  if (options.property.name === "leadFalseMap") {
    options.canShow = false;
  }

  if (
    options?.parentObj?.getType() === "subscription_list" ||
    options?.parentObj?.getType() === "consent"
  ) {
    if (options.property.name === "contactAttributeOptionMap") {
      options.canShow = false;
    }
    if (options.property.name === "leadAttributeOptionMap") {
      options.canShow = false;
    }
  }

  if (options?.parentObj?.getType() === "subscription_list") {
    if (options.property.name === "subscriptionListMap") {
      options.canShow = true;
    }
    if (options.property.name === "subscriptionType") {
      options.canShow = true;
    }
    if (options.property.name === "trueMap") {
      options.canShow = true;
    }
    if (options.property.name === "falseMap") {
      options.canShow = true;
    }
  }

  if (options?.parentObj?.getType() === "consent") {
    if (options.property.name === "leadChoiceMap") {
      options.canShow = true;
    }
    if (options.property.name === "contactChoiceMap") {
      options.canShow = true;
    }
    if (options.property.name === "contactTrueMap") {
      options.canShow = true;
    }
    if (options.property.name === "contactFalseMap") {
      options.canShow = true;
    }
    if (options.property.name === "leadTrueMap") {
      options.canShow = true;
    }
    if (options.property.name === "leadFalseMap") {
      options.canShow = true;
    }
  }
}

export default OnShowingProperty;

import SubscriptionsStateParams from "../interfaces/SubscriptionsStateParams";
import SaveSubscriptionsJson from "../api/SaveSubscriptionsJson";
import SubscriptionsEditorModel from "../interfaces/SubscriptionsEditorModel";
import { DialogType } from "../../common/enums/DialogType";
import i18next from "../../common/localization/i18n";
import SetTheme from "../../common/functions/SetTheme";

const setCookieName = (surveyCreator: any, routeParams: SubscriptionsStateParams) => {
  surveyCreator.survey.cookieName = surveyCreator.survey.limitToOneSubmission
    ? routeParams.webContentId
    : "";
};

const SetSurveyJson = (surveyCreator: any, model: SubscriptionsEditorModel) => {
  surveyCreator.JSON = JSON.parse(model.json);
};

function Save(
  surveyCreator: any,
  routeParams: SubscriptionsStateParams,
  setLoading: any,
  subscriptionsModel: SubscriptionsEditorModel | null,
  dialog: any,
) {
  setLoading(true);
  setCookieName(surveyCreator, routeParams);
  let theme = null;
  if (surveyCreator.themeEditor.isModified) {
    surveyCreator.theme.themeName = "custom";
  }
  theme = surveyCreator.theme;

  SaveSubscriptionsJson(
    routeParams.webContentId,
    surveyCreator.JSON,
    subscriptionsModel?.webContentName,
    theme,
    routeParams,
  )
    ?.then((data) => {
      SetTheme(surveyCreator, data);
      setLoading(false);
    })
    .catch(async (e) => {
      // @ts-ignore
      setLoading(false);
      if (e.message === "Unauthorized") {
        if (
          await dialog(
            DialogType.Confirm,
            i18next.t("NEW_SUBSCRIPTIONS_EDITOR.UNAUTHORIZED_DIALOG.TITLE"),
            i18next.t("NEW_SUBSCRIPTIONS_EDITOR.UNAUTHORIZED_DIALOG.TEXT"),
          )
        ) {
          surveyCreator.setModified("modified");
          surveyCreator.setState("modified");
          return <>getFormJson error</>;
        }
      } else if (
        await dialog(
          DialogType.Confirm,
          i18next.t("NEW_SUBSCRIPTIONS_EDITOR.SAVING_SERVER_DIALOG.TITLE"),
          i18next.t("NEW_SUBSCRIPTIONS_EDITOR.SAVING_SERVER_DIALOG.TEXT"),
        )
      ) {
        surveyCreator.setModified("modified");
        surveyCreator.setState("modified");
        return <>getFormJson error</>;
      }
      return <>getFormJson error</>;
    });
}

export default async function SaveSurveyFunc(
  surveyCreator: any,
  routeParams: SubscriptionsStateParams,
  setLoading: any,
  formModel: SubscriptionsEditorModel | null,
  dialog: any,
  setIsModified: any,
) {
  Save(surveyCreator, routeParams, setLoading, formModel, dialog);
  setIsModified("");
  return "";
}

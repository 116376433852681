import FormStateParams from "../../form-editor/interfaces/FormStateParams";
import SubscriptionsStateParams from "../../subscription-editor/interfaces/SubscriptionsStateParams";
import { EntityType } from "../enums/EntityType";

function GetLeadContactAttributeOptions(
  stateParams: FormStateParams,
  entityType: EntityType,
  attribute: string,
) {
  const sp: FormStateParams | SubscriptionsStateParams = stateParams;
  if (sp === null || sp.webContentId === undefined) {
    return null;
  }

  let url = `${process.env.REACT_APP_API_URL}/api/editorscommon/options?`;
  url += new URLSearchParams({
    accountKey: sp.accountKey,
    sessionId: sp.sessionId,
    userlcid: sp.userlcid,
    orgname: sp.orgname,
    userid: sp.userid,
    entityName: entityType,
    attribute,
  });

  return fetch(url, {
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

export default GetLeadContactAttributeOptions;

import * as SurveyCore from "survey-core";
// @ts-ignore
import * as widgets from "surveyjs-widgets";

export default function CkEditor() {
  const self = window;
  // @ts-ignore
  if (!self.alreadyRendered) {
    const script = document.createElement("script");
    script.src = "https://cdn.ckeditor.com/4.14.1/standard/ckeditor.js";
    document.head.append(script);
    script.onload = () => {
      // @ts-ignore
      const { CKEDITOR } = window;
      CKEDITOR.config.language = "en";
      CKEDITOR.config.versionCheck = false; // LM-4612
      CKEDITOR.config.toolbar = [
        {
          name: "document",
          items: ["-", "Undo", "Redo"],
        },
        {
          name: "basicstyles",
          items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
        },
        {
          name: "links",
          items: ["Link", "Unlink"],
        },
      ];
      // @ts-ignore
      self.alreadyRendered = true;
      widgets.ckeditor(SurveyCore);
      // @ts-ignore
      if (self.forceUpdate) self.forceUpdate(); // need only for REACT
    };
  }
}
